import GraphQLService from './GraphQLService';
import store from '../store/index.js';

export default {
  async updateInstallationAddress(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedOrder.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInstallationAddress($id: String!, $ProductInstallationAddressPayload: ProductInstallationAddressPayload, $event_origin: String!, $changed_by: String!) { 
          updateInstallationAddress(id: $id, payload: $ProductInstallationAddressPayload, event_origin: $event_origin, changed_by: $changed_by ) {
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        ProductInstallationAddressPayload: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateProduct(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateProduct($id: String!, $productObject: ProductPayload, $event_origin: String!, $changed_by: String! ) { 
          updateProduct(id: $id, payload: $productObject, event_origin: $event_origin, changed_by: $changed_by ) {
            id
            changed_by
            event_origin
            created
            field  
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        productObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateInstallations(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInstallations($id: String!, $installationsObject: InstallationsPayload, $event_origin: String!, $changed_by: String! ) { 
          updateInstallations(id: $id, payload: $installationsObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        installationsObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updatePricingsData(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updatePricingsData($id: String!, $pricingsObject: PricingsDataPayload, $event_origin: String!, $changed_by: String! ) { 
          updatePricingsData(id: $id, payload: $pricingsObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        pricingsObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateFilesName(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateFilesName($id: String!, $filesObject: FilesNamePayload, $event_origin: String!, $changed_by: String! ) { 
          updateFilesName(id: $id, payload: $filesObject, event_origin: $event_origin, changed_by: $changed_by){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        filesObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateAppointmentForConnection(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateAppointmentForConnection($id: String!, $appointmentObject: AppointmentForConnectionPayload, $event_origin: String!, $changed_by: String! ) { 
          updateAppointmentForConnection(id: $id, payload: $appointmentObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        appointmentObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateInstallationCompany(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInstallationCompany($id: String!, $InstallerCompaniesObject: InstallerCompaniesPayload, $event_origin: String!, $changed_by: String! ) { 
          updateInstallationCompany(id: $id, payload: $InstallerCompaniesObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        InstallerCompaniesObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateApplicant(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedOrder.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateApplicant($id: String!, $ApplicantObject: ApplicantDataPayload, $event_origin: String!, $changed_by: String! ) { 
          updateApplicant(id: $id, payload: $ApplicantObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        ApplicantObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateTechnicalInspectionData(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateTechnicalInspectionData($id: String!, $technicalInspectionObject: TechnicalInspectionDataPayload, $event_origin: String!, $changed_by: String! ) { 
          updateTechnicalInspectionData(id: $id, payload: $technicalInspectionObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            status
          }
        }`,
      {
        id: requestObject.id,
        technicalInspectionObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateInvoiceAddress(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedOrder.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInvoiceAddress($id: String!, $InvoiceAddressObject: InvoiceAddressPayload, $event_origin: String!, $changed_by: String! ) { 
          updateInvoiceAddress(id: $id, payload: $InvoiceAddressObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        InvoiceAddressObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateAdditionalContactPerson(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedOrder.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateAdditionalContactPerson($id: String!, $ContactPersonObject: AdditionalContactPersonPayload, $event_origin: String!, $changed_by: String! ) { 
          updateAdditionalContactPerson(id: $id, payload: $ContactPersonObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        ContactPersonObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateLandowner(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedOrder.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateLandowner($id: String!, $LandownerObject: LandownerPayload, $event_origin: String!, $changed_by: String! ) { 
          updateLandowner(id: $id, payload: $LandownerObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        LandownerObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateConstructionData(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateConstructionData($id: String!, $ConstructionDataObject: ConstructionDataPayload, $event_origin: String!, $changed_by: String!) { 
          updateConstructionData(id: $id, payload: $ConstructionDataObject, event_origin: $event_origin, changed_by: $changed_by){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
            title
          }
        }`,
      {
        id: requestObject.id,
        ConstructionDataObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateOrderStatus(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateOrderStatus($id: String!, $OrderStatusDataPayload: OrderStatusDataPayload, $event_origin: String!, $changed_by: String!) { 
          updateOrderStatus(id: $id, payload: $OrderStatusDataPayload, event_origin: $event_origin, changed_by: $changed_by){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        OrderStatusDataPayload: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async putInstallerDocumentGraphQL(fileName, stage, encrypted) {
    return GraphQLService.requestWithOpenID(
      `
        query uploadFileS3 ($bucketName: String, $fileName: String, $encrypted: Boolean){
          uploadFile(bucket: $bucketName, key: $fileName, encrypted: $encrypted)
        }`,
      {
        encrypted: Boolean(encrypted),
        fileName: fileName,
        bucketName: `netzportal-installers-concessions-${stage}`,
      }
    );
  },
  async updateInstallerFilesName(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInstallerFilesName($id: String!, $filesObject: InstallerFilesNamePayload, $event_origin: String!, $changed_by: String! ) { 
          updateInstallerFilesName(id: $id, payload: $filesObject, event_origin: $event_origin, changed_by: $changed_by){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        filesObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateInstallerCompanyData(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedInstaller.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInstallerCompanyData($id: String!, $InstallerCompanyDataObject: InstallerCompanyDataPayload, $event_origin: String!, $changed_by: String! ) { 
          updateInstallerCompanyData(id: $id, payload: $InstallerCompanyDataObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        InstallerCompanyDataObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateInstallerPeople(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedInstaller.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInstallerPeople($id: String!, $InstallerPeopleObject: InstallerPeoplePayload, $event_origin: String!, $changed_by: String! ) { 
          updateInstallerPeople(id: $id, payload: $InstallerPeopleObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        InstallerPeopleObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateInstallerConcessionStatus(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedInstaller.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInstallerConcessionStatus($id: String!, $InstallerConcessionStatusDataObject: InstallerConcessionStatusDataPayload, $event_origin: String!, $changed_by: String! ) { 
          updateInstallerConcessionStatus(id: $id, payload: $InstallerConcessionStatusDataObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
          }
        }`,
      {
        id: requestObject.id,
        InstallerConcessionStatusDataObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateInstallerInspectionData(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInstallerInspectionData($id: String!, $installerInspectionObject: InstallerInspectionDataPayload, $event_origin: String!, $changed_by: String! ) { 
          updateInstallerInspectionData(id: $id, payload: $installerInspectionObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            status
          }
        }`,
      {
        id: requestObject.id,
        installerInspectionObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async deliverInstallerBadge(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation deliverInstallerBadge($id: String!, $filesObject: InstallerBadgeDataPayload, $event_origin: String!, $changed_by: String! ) { 
          deliverInstallerBadge(id: $id, payload: $filesObject, event_origin: $event_origin, changed_by: $changed_by){
            id
            changed_by
            event_origin
            created
            field 
          }
        }`,
      {
        id: requestObject.id,
        filesObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateInstallerContactPerson(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInstallerContactPerson($id: String!, $ContactPersonDataObject: InstallerContactPersonPayload, $event_origin: String!, $changed_by: String! ) { 
          updateInstallerContactPerson(id: $id, payload: $ContactPersonDataObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        ContactPersonDataObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateInstallerConcessionDivision(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateInstallerConcessionDivision($id: String!, $InstallerConcessionDivisionDataObject: InstallerConcessionDivisionPayload, $event_origin: String!, $changed_by: String! ) { 
          updateInstallerConcessionDivision(id: $id, payload: $InstallerConcessionDivisionDataObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        InstallerConcessionDivisionDataObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateOrderEmobilityWallboxLocation(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedOrder.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateOrderEmobilityWallboxLocation($id: String!, $wallboxLocationObject: EmobilityWallboxLocationPayload, $event_origin: String!, $changed_by: String! ) { 
          updateOrderEmobilityWallboxLocation(id: $id, payload: $wallboxLocationObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        wallboxLocationObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateOrderEmobilityChargingPower(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedOrder.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateOrderEmobilityChargingPower($id: String!, $ChargingPowerObject: EmobilityChargingPowerPayload, $event_origin: String!, $changed_by: String! ) { 
          updateOrderEmobilityChargingPower(id: $id, payload: $ChargingPowerObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        ChargingPowerObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateOrderEmobilityFilesName(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateOrderEmobilityFilesName($id: String!, $filesObject: OrderEmobilityFilesNamePayload, $event_origin: String!, $changed_by: String! ) { 
          updateOrderEmobilityFilesName(id: $id, payload: $filesObject, event_origin: $event_origin, changed_by: $changed_by){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        filesObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  resetOrderEmobilityPayload(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation resetOrderEmobilityPayload($id: String!, $orderEmobilityPayloadObject: OrderEmobilityPayload, $event_origin: String!, $changed_by: String! ) { 
          resetOrderEmobilityPayload(id: $id, payload: $orderEmobilityPayloadObject, event_origin: $event_origin, changed_by: $changed_by){
            id
            changed_by
            event_origin
            new_value
            field
          }
        }`,
      {
        id: requestObject.id,
        orderEmobilityPayloadObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
};
