import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import { StandardTheme } from '@newnetzportal/netzportal-webapp-storybook-components';
import '@mdi/font/css/materialdesignicons.css';

const lightTheme = StandardTheme.theme.themes.light;

import NewCheckboxBlankOutline from '../components/new-custom-elements/icons/NewCheckboxBlankOutline.vue';
import NewCheckboxMarked from '../components/new-custom-elements/icons/NewCheckboxMarked.vue';
import NewRadioboxBlank from '../components/new-custom-elements/icons/NewRadioboxBlank.vue';
import NewRadioboxMarked from '../components/new-custom-elements/icons/NewRadioboxMarked.vue';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);
const customTranslations = {
  'input.clear': 'Clear',
};

export default new Vuetify({
  theme: {
    themes: {
      light: {
        ...lightTheme,
      },
      // TODO Add Dark theme
    },
    options: { customProperties: true },
  },
  icons: {
    defaultSet: 'mdi',
    values: {
      radioboxBlank: {
        // name of our custom icon
        component: NewRadioboxBlank, // our custom component
      },
      radioboxMarked: {
        component: NewRadioboxMarked,
      },
      checkboxBlankOutline: {
        component: NewCheckboxBlankOutline,
      },
      checkboxMarked: {
        component: NewCheckboxMarked,
      },
    },
  },
  lang: {
    locales: { en: { ...en, ...customTranslations } },
    current: 'en',
  },
});
