<template>
  <div>
    <v-row style="justify-content: flex-end; margin-right: 0px"> </v-row>
    <single-raw
      first
      class="single-raw"
      title="Bestelldatum"
      :editable="false"
      >{{
        new Date(orderDetails.requestDate) | formatDate('DD.MM.YYYY HH:mm')
      }}</single-raw
    >
    <single-raw
      class="single-raw"
      :title="$t('order_details.connection_address')"
      @edit="
        $emit('show-modal', {
          component: 'InstallationAddressComponent',
          method: 'updateInstallationAddress',
          location: 'orderDetailsComponent',
        })
      "
    >
      <div v-html="orderDetails.installationAddress"></div>
      <userfeedback-component
        v-if="
          this.notifyEvent.field === 'updateInstallationAddress' &&
          this.lastUpdatedLocation === 'orderDetailsComponent'
        "
      />
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('order_details.connection_type')"
      @edit="
        $emit('show-modal', {
          component: 'ProductAvailabilityComponent',
          method: 'updateProduct',
          location: 'orderDetailsComponent',
        })
      "
      ><div v-html="`${$t(orderDetails.product)} `"></div>
      <userfeedback-component
        v-if="this.notifyEvent.field === 'updateProduct'"
      />
    </single-raw>

    <single-raw
      v-if="selectedOrder.journey !== 'eMobility'"
      class="single-raw"
      :title="$t('order_details.installations')"
      @edit="
        $emit('show-modal', {
          component: 'InstallationDialog',
          method: 'updateInstallations',
          location: 'orderDetailsComponent',
        })
      "
      ><div v-html="orderDetails.selectedInstallations"></div>
      <userfeedback-component
        v-if="this.notifyEvent.field === 'updateInstallations'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('order_details.total_price')"
      @edit="
        $emit('show-modal', {
          component: 'PricingComponent',
          method: 'updatePricingsData',
          location: 'orderDetailsComponent',
        })
      "
    >
      <pricing-details-component
        :totalPrice="orderDetails.totalPrice"
        :priceDetails="orderDetails.priceDetails"
        :netWorkConnection="selectedOrder.preferred_network_connection"
      />
      <userfeedback-component
        v-if="this.notifyEvent.field === 'updatePricingsData'"
      />
    </single-raw>

    <order-file-row
      class="single-raw"
      :title="$t('order_details.blueprints')"
      :filesArray="selectedFiles"
      @edit="
        $emit('show-modal', {
          component: 'OrderFileUploadComponent',
          method: 'updateFilesName',
          location: 'orderDetailsComponent',
          properties: { filesObject: $event },
        })
      "
    >
      <userfeedback-component
        v-if="
          this.notifyEvent.field === 'updateFilesName' &&
          this.lastUpdatedLocation === 'orderDetailsComponent'
        "
      />
    </order-file-row>

    <single-raw
      class="single-raw"
      :title="$t('order_details.appointment')"
      @edit="
        $emit('show-modal', {
          component: 'AppointmentDialog',
          method: 'updateAppointmentForConnection',
          location: 'orderDetailsComponent',
        })
      "
      >{{ orderDetails.appointment }}
      <userfeedback-component
        v-if="this.notifyEvent.field === 'updateAppointmentForConnection'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      @edit="
        $emit('show-modal', {
          component: 'InstallerCompaniesComponent',
          method: 'updateInstallationCompany',
          location: 'orderDetailsComponent',
        })
      "
      :title="$t('order_details.installation_company')"
    >
      <div v-html="orderDetails.installationCompanies"></div>
      <userfeedback-component
        v-if="this.notifyEvent.field === 'updateInstallationCompany'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      @edit="
        $emit('show-modal', {
          component: 'AppplicantComponent',
          method: 'updateApplicant',
          location: 'orderDetailsComponent',
        })
      "
      :title="$t('order_details.applicant')"
    >
      <div v-html="orderDetails.applicant"></div>

      <userfeedback-component
        v-if="
          this.notifyEvent.field === 'updateApplicant' &&
          this.lastUpdatedLocation === 'orderDetailsComponent'
        "
      />
    </single-raw>

    <single-raw
      class="single-raw"
      @edit="
        $emit('show-modal', {
          component: 'InvoiceAddressComponent',
          method: 'updateInvoiceAddress',
          location: 'orderDetailsComponent',
        })
      "
      :title="$t('order_details.alternative_invoice_address')"
    >
      <div v-html="orderDetails.invoiceReceiver"></div>
      <userfeedback-component
        v-if="this.notifyEvent.field === 'updateInvoiceAddress'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      @edit="
        $emit('show-modal', {
          component: 'AdditionalContactComponent',
          method: 'updateAdditionalContactPerson',
          location: 'orderDetailsComponent',
        })
      "
      :title="$t('order_details.additional_contact_person')"
    >
      <div v-html="orderDetails.otherContacts"></div>
      <userfeedback-component
        v-if="this.notifyEvent.field === 'updateAdditionalContactPerson'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      @edit="
        $emit('show-modal', {
          component: 'LandownerComponent',
          method: 'updateLandowner',
          location: 'orderDetailsComponent',
        })
      "
      :title="$t('order_details.landowner')"
      :last="!showEmobilityOptions"
    >
      <div v-html="orderDetails.landowner"></div>
      <userfeedback-component
        v-if="this.notifyEvent.field === 'updateLandowner'"
      />
    </single-raw>

    <div v-if="showEmobilityOptions">
      <single-raw
        class="single-raw"
        :title="$t('order_details.wallbox_location')"
        @edit="
          $emit('show-modal', {
            component: 'EmobilityWallboxLocationComponent',
            method: 'updateOrderEmobilityWallboxLocation',
            location: 'orderDetailsComponent',
          })
        "
      >
        <div>
          {{ wallboxLocationObject(selectedOrder.emobility_payload) }}
        </div>
        <userfeedback-component
          v-if="notifyEvent.field === 'updateOrderEmobilityWallboxLocation'"
        />
      </single-raw>

      <single-raw
        class="single-raw"
        :title="$t('emobility_details.charging_power')"
        @edit="
          $emit('show-modal', {
            component: 'EmobilityChargingPowerComponent',
            method: 'updateOrderEmobilityChargingPower',
            location: 'orderDetailsComponent',
          })
        "
      >
        <div>
          {{ emobilityInstallationObject(selectedOrder.emobility_payload) }}
        </div>
        <userfeedback-component
          v-if="notifyEvent.field === 'updateOrderEmobilityChargingPower'"
        />
      </single-raw>

      <single-raw
        class="single-raw"
        :title="$t('order_details.emobility_plans')"
        @edit="
          $emit('show-modal', {
            component: 'OrderEmobilityFileUploadComponent',
            method: 'updateOrderEmobilityFilesName',
            location: 'orderDetailsComponent',
          })
        "
        :last="!!showEmobilityOptions"
      >
        <div v-if="!!selectedOrder.emobility_payload.file_uploads">
          <div>
            {{ $t('emobility_details.site_plan') }}
            <v-btn
              icon
              v-for="(item, index) in selectedOrder.emobility_payload
                .file_uploads"
              :key="item"
              @click="downloadOrderFile(item, index)"
              ><v-icon>mdi-download</v-icon></v-btn
            >
          </div>
        </div>
        <div v-else>
          <div>-</div>
        </div>
        <userfeedback-component
          v-if="notifyEvent.field === 'updateOrderEmobilityFilesName'"
        />
      </single-raw>
    </div>

    <ErrorDialog :show-error-dialog="showErrorDialog" @close="closeDialog" />
  </div>
</template>

<script>
import SingleRaw from './SingleRaw';
import PricingDetailsComponent from './PricingDetailsComponent';
import { transformRawDataToOderDetailFormat } from './transform_raw_data_to_oder_detail_format';
import ProductAvailabilityService from '../../services/ProductAvailabilityService.js';
import { EventBus } from '../../main';
import ErrorDialog from './ErrorDialog';
import { emptyEventData } from '../../store/ordersModule';
import OrderFileRow from './OrderFileRow';
import UserfeedbackComponent from '../emobility-details/UserfeedbackComponent.vue';
import downloadOrderFileMethods from './downloadOrderFilesMethods';
import fileMethods from '../../helpers/fileMethods';
import {
  wallboxLocationObject,
  emobilityInstallationObject,
} from '../../helpers/transformToPresentableData';

export default {
  name: 'OrderDetails',
  components: {
    SingleRaw,
    PricingDetailsComponent,
    ErrorDialog,
    OrderFileRow,
    UserfeedbackComponent,
  },
  props: {
    emob_file_names: {
      type: Object,
    },
    water_file_name: {
      type: Object,
    },
  },
  data() {
    return {
      currentComponent: '',
      backendMethod: '',
      showErrorDialog: false,
      alert: false,
      selectedFiles: [],
    };
  },
  computed: {
    showEmobilityOptions() {
      return !!this.selectedOrder?.electric_installations?.e_charging_station;
    },
    selectedOrder() {
      return this.$store.getters.getSelectedOrder;
    },
    blueprints() {
      return this.$store.getters.getOrderBlueprints;
    },
    cadastrals() {
      return this.$store.getters.getOrderCadastrals;
    },
    coordinates() {
      return this.$store.getters.getOrderCoordinates;
    },
    orderDetails() {
      return transformRawDataToOderDetailFormat(
        this.$store.getters.getSelectedOrder
      );
    },
    notifyEvent() {
      return this.$store.getters.getNotifyEvent
        ? this.$store.getters.getNotifyEvent
        : emptyEventData;
    },
    lastUpdatedLocation() {
      return this.$store.getters.getLastUpdatedLocation;
    },
  },
  created() {
    EventBus.$on('all-order-files-uploaded', this.fileUploadDone);
    EventBus.$on('signed-url-error', this.handleError);
    this.$store.commit('setCoordinates', this.coordinates);
    this.getProductAvailability(this.coordinates);
    this.$store.commit('setLastUpdatedComponent', '');
    this.$store.commit('setLastUpdatedLocation', '');
    this.selectedFiles = this.getSelectedFiles();

    //get emobility files from backend
    for (const [key, value] of Object.entries(this.emob_file_names)) {
      if (value) {
        downloadOrderFileMethods.getOrderDocument(
          this.selectedOrder.id,
          value,
          key
        );
      }
    }

    //get mfh water file from backend
    for (const [key, value] of Object.entries(this.water_file_name)) {
      if (value) {
        downloadOrderFileMethods.getOrderDocument(
          this.selectedOrder.id,
          value,
          key
        );
      }
    }
  },
  watch: {
    notifyEvent() {
      if (this.lastUpdatedLocation === 'orderDetailsComponent') {
        setTimeout(() => {
          this.$store.commit('setNotifyEvent', emptyEventData);
        }, 3000);
      }
    },
    blueprints() {
      this.selectedFiles = this.getSelectedFiles();
    },
    cadastrals() {
      this.selectedFiles = this.getSelectedFiles();
    },
  },
  methods: {
    wallboxLocationObject(data) {
      return wallboxLocationObject(data);
    },
    emobilityInstallationObject(data) {
      return emobilityInstallationObject(data);
    },
    getProductAvailability(coordinates) {
      ProductAvailabilityService.getProductAvailability(coordinates)
        .then((result) => {
          if (result.getProductAvailability.length !== 0) {
            if (this.$store.getters.getSelectedOrder.product === 'eMobility') {
              this.setProductAvailability(['eMobility']);
            } else {
              this.isAddressOutsideTheNetworkArea = false;
              this.convertResourcesToProducts(result.getProductAvailability);
            }
          } else {
            this.isAddressOutsideTheNetworkArea = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSelectedFiles() {
      const fileArray = [];
      if (this.selectedOrder.journey !== 'eMobility') {
        fileArray.push({
          file: '',
          file_names: [],
          position: 1,
          type: 'blueprint',
          required: true,
        });
      }
      fileArray.push({
        file: '',
        file_names: [],
        position: 1,
        type: 'cadastral',
        required: true,
      });
      if (this.selectedOrder.journey !== 'eMobility') {
        this.blueprints.forEach((element) => {
          if (element) {
            fileArray[0].file_names.push(element);
          }
        });
        this.cadastrals.forEach((element) => {
          if (element) {
            fileArray[1].file_names.push(element);
          }
        });
      } else {
        this.cadastrals.forEach((element) => {
          if (element) {
            fileArray[0].file_names.push(element);
          }
        });
      }
      return fileArray;
    },
    closeDialog() {
      this.showErrorDialog = false;
    },
    convertResourcesToProducts(response) {
      let products;
      if (['electricity', 'gas', 'water'].every((e) => response.includes(e))) {
        products = ['electricity&water', 'gas', 'electricity&water&gas'];
      } else if (['electricity', 'gas'].every((e) => response.includes(e))) {
        products = ['electricity', 'gas', 'electricity&gas'];
      } else {
        products = response;
      }
      this.setProductAvailability(products);
    },
    setProductAvailability(data) {
      this.$store.commit('updateAvailableProducts', data);
    },
    handleError(err) {
      console.log(err);
      this.showErrorDialog = true;
    },
    fileUploadDone() {
      console.log('Files uploaded');
    },
    downloadOrderFile(item, index) {
      const file_name =
        item.substring(0, 3) !== 'em_' ? `em_${index + 1}` + item : item;
      fileMethods.downloadFile(file_name, 'Order');
    },
  },
};
</script>

<style scoped>
.single-raw {
  margin: 0 0 1px 0;
}
.back-button {
  border-radius: 12px 12px 0 0;
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0;
  height: 48px !important;
  width: 90px;
  box-shadow: none;
}

.alert_success {
  background-color: #e4f0d6 !important;
  display: flex;
  margin-top: 10px;
}
</style>
