import { COMPANY_TITLE, CITY_TITLE } from '../helpers/constants';

const emptyConstructionPlannedDate = {
  start_time: {
    start_hour: '08',
    start_minute: '00',
  },
  end_time: {
    end_hour: '18',
    end_minute: '00',
  },
  dates: [new Date().toISOString().slice(0, 10)],
};

const emptyCompany = {
  company_name: '',
  contact_person: '',
  construction_planned_date: emptyConstructionPlannedDate,
};

export const emptyEventData = {
  changed_by: '',
  created: '',
  event_origin: '',
  field: '',
  id: '',
  new_value: '',
  note: '',
  old_value: '',
  payload: '',
};
export const ordersModule = {
  state: {
    selectedOrder: {},
    journeyFilter: 'alle',
    orderStatusFilter: '',
    regionalTeamFilter: '',
    notifyEvent: emptyEventData,
    lastUpdatedComponent: '',
    lastUpdatedLocation: '',
  },
  mutations: {
    deleteSelectedOrder(state) {
      state.selectedOrder = {};
    },
    setSelectedOrder(state, selectedOrder) {
      state.selectedOrder = selectedOrder;
      if (!selectedOrder.internal_construction_management) {
        state.selectedOrder.internal_construction_management = {
          regional_team: selectedOrder.regional_team,
          construction_manager: '',
          construction_planned_date: emptyConstructionPlannedDate,
        };
      }
      if (!selectedOrder.groundwork_company) {
        state.selectedOrder.groundwork_company = {
          company_name: '',
          contact_person: '',
          construction_planned_date: emptyConstructionPlannedDate,
          groundwork_by_client: false,
        };
      }
      if (
        (selectedOrder.product.includes('electricity') ||
          selectedOrder.product.includes('eMobility')) &&
        !selectedOrder.assembly_electricity_company
      ) {
        state.selectedOrder.assembly_electricity_company = emptyCompany;
      }
      if (
        (selectedOrder.product.includes('water') ||
          selectedOrder.product.includes('gas')) &&
        !selectedOrder.assembly_gas_water_company
      ) {
        state.selectedOrder.assembly_gas_water_company = emptyCompany;
      }
    },
    updateSelectedOrderEmobilityData(state, newValue) {
      if (state.selectedOrder.emobility_payload === null) {
        state.selectedOrder.emobility_payload = {};
      }
      Object.keys(newValue).forEach((changedPart) => {
        state.selectedOrder.emobility_payload[changedPart] =
          newValue[changedPart];
      });
    },
    updateSelectedOrderData(state, newValue) {
      Object.keys(newValue).forEach((changedPart) => {
        switch (changedPart) {
          case 'product_installation_full_address':
            state.selectedOrder.product_installation_with_plot_number_address =
              null;
            state.selectedOrder[changedPart] = newValue[changedPart];
            break;
          case 'product_installation_with_plot_number_address':
            state.selectedOrder.product_installation_full_address = null;
            state.selectedOrder[changedPart] = newValue[changedPart];
            break;
          case 'invoice_address':
            if (newValue.alternative_invoice === 'address') {
              state.selectedOrder.applicant.alternative_invoice_address =
                newValue.invoice_address.address;
            } else if (newValue.alternative_invoice === 'recipient') {
              state.selectedOrder.alternative_invoice_recipient =
                newValue.invoice_address;
            }
            state.selectedOrder[changedPart] = newValue[changedPart];
            break;
          case 'combined_pricings':
            Object.keys(newValue[changedPart]).forEach((key) => {
              state.selectedOrder.combined_pricings[key] =
                newValue.combined_pricings[key];
            });
            break;
          default:
            state.selectedOrder[changedPart] = newValue[changedPart];
            break;
        }
      });
    },
    updateLastChangedByInSelectedOrder(state, last_changed_by) {
      state.selectedOrder.last_changed_by = last_changed_by;
    },
    updateLastUpdateInSelectedOrder(state, last_update) {
      state.selectedOrder.last_update = last_update;
    },
    setNotifyEvent(state, notifyEvent) {
      state.notifyEvent = notifyEvent;
    },
    setLastUpdatedComponent(state, lastUpdatedComponent) {
      state.lastUpdatedComponent = lastUpdatedComponent;
    },
    setLastUpdatedLocation(state, lastUpdatedLocation) {
      state.lastUpdatedLocation = lastUpdatedLocation;
    },
    setJourneyFilter(state, journeyFilter) {
      state.journeyFilter = journeyFilter;
    },
    setOrderRegionalTeamFilter(state, regionalTeamFilter) {
      state.regionalTeamFilter = regionalTeamFilter;
    },
    setOrderstatusFilter(state, orderStatusFilter) {
      state.orderStatusFilter = orderStatusFilter;
    },
    setSelectedOrderCalendar(state, selectedOrderCalendar) {
      state.selectedOrderCalendar = selectedOrderCalendar;
    },
  },
  getters: {
    getSelectedOrder(state) {
      return state.selectedOrder;
    },
    getSelectedOrderCalendar(state) {
      return state.selectedOrderCalendar;
    },
    getNotifyEvent(state) {
      return state.notifyEvent;
    },
    getLastUpdatedComponent(state) {
      return state.lastUpdatedComponent;
    },
    getLastUpdatedLocation(state) {
      return state.lastUpdatedLocation;
    },
    getJourneyFilter(state) {
      return state.journeyFilter;
    },
    getOrderRegionalTeamFilter(state) {
      return state.regionalTeamFilter;
    },
    getOrderstatusFilter(state) {
      return state.orderStatusFilter;
    },
    getSelectedOrderApplicantName(state) {
      let applicantName = '';
      if (state.selectedOrder.applicant) {
        if (state.selectedOrder.applicant.title === COMPANY_TITLE) {
          applicantName = state.selectedOrder.applicant.company_name;
        } else if (state.selectedOrder.applicant.title === CITY_TITLE) {
          applicantName = state.selectedOrder.applicant.city_name;
        } else {
          applicantName =
            (state.selectedOrder.applicant.title
              ? state.selectedOrder.applicant.title
              : '') +
            ' ' +
            state.selectedOrder.applicant.name +
            ' ' +
            state.selectedOrder.applicant.surname;
        }
      }
      return applicantName;
    },
    getSelectedOrderAdress(state) {
      let installationAddress = '';
      if (state.selectedOrder.product_installation_full_address) {
        installationAddress =
          state.selectedOrder.product_installation_full_address.street +
          ' ' +
          state.selectedOrder.product_installation_full_address.street_number +
          ', ' +
          state.selectedOrder.product_installation_full_address.zip +
          ' ' +
          state.selectedOrder.product_installation_full_address.city;
      } else if (
        state.selectedOrder.product_installation_with_plot_number_address
      ) {
        installationAddress =
          state.selectedOrder.product_installation_with_plot_number_address
            .new_building_area_name +
          ', ' +
          'Gemarkung: ' +
          state.selectedOrder.product_installation_with_plot_number_address
            .district +
          ', ' +
          'Flur: ' +
          state.selectedOrder.product_installation_with_plot_number_address
            .landing +
          ', ' +
          'Flurstück: ' +
          state.selectedOrder.product_installation_with_plot_number_address
            .plot +
          ', ' +
          state.selectedOrder.product_installation_with_plot_number_address
            .zip +
          ', ' +
          state.selectedOrder.product_installation_with_plot_number_address
            .location;
      }
      return installationAddress;
    },
    getSelectedOrderInspectionData(state) {
      const selectedOrder = state.selectedOrder;
      const inspectionData = {
        customer_information_checked:
          !!selectedOrder.customer_information_checked,
        blueprints_checked: !!selectedOrder.blueprints_checked,
        gis_checked: !!selectedOrder.gis_checked,
        address_checked: !!selectedOrder.address_checked,
        connection_data_checked: !!selectedOrder.connection_data_checked,
        connection_data_electricity: selectedOrder.connection_data_electricity,
        connection_data_gas: selectedOrder.connection_data_gas,
      };
      if (selectedOrder.emobility_payload) {
        inspectionData.emobility_charging_power_checked =
          !!selectedOrder.emobility_charging_power_checked;
        inspectionData.emobility_charging_power_values =
          selectedOrder.emobility_charging_power_values
            ? selectedOrder.emobility_charging_power_values
            : {
                emobility_charging_power_inspection: '',
                emobility_charging_power_custom_inspection: '',
              };
      }
      return inspectionData;
    },
    getOrderBlueprints(state) {
      return state.selectedOrder.uploaded_file_names_blueprint
        ? state.selectedOrder.uploaded_file_names_blueprint
        : [];
    },
    getOrderCadastrals(state) {
      return state.selectedOrder.uploaded_file_names_cadastral
        ? state.selectedOrder.uploaded_file_names_cadastral
        : [];
    },
    getOrderCoordinates(state) {
      return state.selectedOrder.coordinates
        ? state.selectedOrder.coordinates
        : { lat: null, lng: null };
    },
  },
};
