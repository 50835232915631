<template>
  <div>
    <TechnicalInspectionNetzanschluss
      @send-inquiry="sendInquiry($event)"
      @save-current-result="updateTechnicalInspectionValues"
      @confirm-check="confirmCheck()"
      :contactDataArray="contactInspectionDataArray"
      @decline-check="openModal"
    >
      <template v-slot:button-loading v-if="showLoading">
        <v-progress-circular
          indeterminate
          color="white"
          class="ml-4"
        ></v-progress-circular>
      </template>
      <template v-slot:button-manuell>
        <button
          type="button"
          class="btn-confirm-inspection"
          style="margin-left: 30px"
          @click="switchToManualStatus()"
        >
          <v-progress-circular
            v-if="showLoadingManuell"
            indeterminate
            color="white"
            class="ml-6s"
          ></v-progress-circular>
          <div class="btn-text" style="width: inherit; color: white">
            manuelle Bearbeitung
          </div>
        </button>
      </template>
      <template v-slot:save-alert>
        <v-alert
          class="alert_success ml-4"
          style="background-color: #e4f0d6 !important"
          :value="alert"
          max-width="270px"
          max-height="35px"
          text
          transition="scale-transition"
          v-if="lastUpdatedComponent === 'saveCurrentResult'"
        >
          <template v-slot:append>
            <v-icon color="green" class="ml-2">mdi-check</v-icon>
          </template>
          Erfolgreich gespeichert
        </v-alert>
      </template>
      <template v-if="notEditable" v-slot:footer>
        <button
          type="button"
          class="btn-confirm-inspection"
          @click="$emit('closeTechnicalInspection')"
        >
          <div class="btn-text" style="width: inherit; color: white">
            Schließen
          </div>
        </button>
      </template>
      <template v-slot:checkbox-list>
        <div
          v-for="(checkboxActionData, index) in checkboxActionConfig"
          v-bind:key="checkboxActionData.title"
          class="mr-4"
        >
          <TextCheckboxActionComponent
            :notEditable="notEditable"
            :title="checkboxActionData.title"
            @update-checkbox-value="checkboxActionData.method"
            :index="index"
            :checkboxName="checkboxActionData.checkboxName"
            :requiredText="checkboxActionData.required_text"
            :showError="showError"
            @show-modal="$emit('show-modal', $event)"
            :updateMethod="checkboxActionData.updateMethod"
            :updateComponent="checkboxActionData.updateComponent"
          >
          </TextCheckboxActionComponent>
        </div>
        <div
          v-for="(checkboxData, index) in checkBoxConfig"
          v-bind:key="checkboxData.title"
          class="mr-4"
        >
          <TextCheckboxComponent
            :notEditable="notEditable"
            :title="checkboxData.title"
            @update-checkbox-value="checkboxData.method"
            :index="index"
            :checkboxName="checkboxData.checkboxName"
            :requiredText="checkboxData.required_text"
            :showError="showError"
            :indexData="checkboxData.index"
          />
        </div>
        <connection-data-component :notEditable="notEditable" class="mr-4" />
        <ChargingPowerComponent
          v-if="includesChargingStation"
          :notEditable="notEditable"
          :type="'Technical'"
          :index="6"
          class="mr-4"
        />
        <div class="error-dialog" v-if="showError && !allDataSelected">
          {{ $t('technical_inspection.error_required') }}
        </div>
      </template>
      <template v-slot:hint-component>
        <hint-component
          v-if="
            selectedOrder.preferred_network_connection === true ||
            selectedOrder.same_landowner === false
          "
          :data="selectedOrder"
        />
      </template>

      <template v-slot:note-document-upload>
        <div v-if="!isNoteUploadInfo">
          <NEWNoteUploadComponent
            @send-note-and-uploadfile="sendNoteAndFile($event)"
            @note-data-deleted="deleteNote = false"
            :deleteNote="deleteNote"
            :labels="noteLabels"
            class="mb-4"
          >
            <template v-slot:status-note-alert>
              <v-alert
                class="alert_success"
                style="background-color: #e4f0d6 !important"
                max-width="270px"
                max-height="35px"
                text
                transition="scale-transition"
                v-if="
                  lastUpdatedComponent === 'noteUploadFileComponent' && alert
                "
              >
                <template v-slot:append>
                  <v-icon color="green" class="ml-2">mdi-check</v-icon>
                </template>
                Erfolgreich gespeichert
              </v-alert>
              <v-alert
                type="error"
                class="alert_error mt-4"
                max-width="270px"
                v-if="alertErrorNote"
                text
                transition="scale-transition"
              >
                Speicherung fehlgeschlagen
              </v-alert>
            </template>
          </NEWNoteUploadComponent>
        </div>
        <div v-else class="container--note-upload-pending">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="60"
            :width="5"
          />
          <NewAlert
            class="mt-4"
            color="INFO"
            :message="$t('technical_inspection.info_file_upload')"
          />
        </div>
      </template>

      <template v-slot:status-alert>
        <v-alert
          class="alert_success"
          style="background-color: #e4f0d6 !important"
          max-width="270px"
          max-height="35px"
          text
          transition="scale-transition"
          v-if="lastUpdatedComponent === 'noteContactComponent' && alert"
        >
          <template v-slot:append>
            <v-icon color="green" class="ml-2">mdi-check</v-icon>
          </template>
          Erfolgreich gespeichert
        </v-alert>
        <v-alert
          type="error"
          class="alert_error mt-4"
          max-width="270px"
          v-if="alertError"
          text
          transition="scale-transition"
        >
          Speicherung fehlgeschlagen
        </v-alert>
        <div class="error-dialog" style="margin-top: 10px" v-if="showErrors">
          {{ $t('installation_address.inputs_error') }}
        </div>
      </template>
    </TechnicalInspectionNetzanschluss>

    <NewModalDialog
      v-if="isModalVisible"
      :value="noteValue"
      role="dialog"
      @cancel-data="closeModal"
      @update-note-data="noteValue.note = $event"
      @save-data="closeModalAndSaveData"
    >
      <template v-slot:body>
        <div class="dialogTitle">Prüfung ablehnen</div>
      </template>
      <template v-slot:footer>
        <div class="footer_container">
          <div v-if="showNoteError" class="error_message_dialog">
            Bitte Ablehnungsgrund eingeben.
          </div>
          <div class="button_container">
            <button
              type="button"
              class="btn-save"
              @click="closeModalAndSaveData"
            >
              Prüfung ablehnen
            </button>
            <button type="button" class="btn-cancel" @click="closeModal">
              abbrechen
            </button>
            <v-alert
              type="error"
              class="alert_error mt-4"
              max-width="270px"
              v-if="alertErrorDecline"
              text
              transition="scale-transition"
            >
              Ablehnen fehlgeschlagen
            </v-alert>
          </div>
        </div>
      </template>
    </NewModalDialog>
  </div>
</template>

<script>
import noteService from '../../services/NoteService';
import TextCheckboxComponent from './TextCheckboxComponent.vue';
import TextCheckboxActionComponent from '../order-steps-components/TextCheckboxActionComponent';
import ConnectionDataComponent from './ConnectionDataComponent';
import ChargingPowerComponent from '../emobility-inspection-component/ChargingPowerComponent.vue';
import { emptyChargingPowerValues } from '../../store/emobilityInspectionModule';
import {
  emptyConnectionDataGas,
  emptyConnectionDataElectricity,
} from '../../store/technicalInspectionModule';
import HintComponent from '../shared-components/HintComponent/HintComponent.vue';
import {
  NEWNoteUploadComponent,
  NewAlert,
  NewModalDialog,
  TechnicalInspectionNetzanschluss,
} from '@newnetzportal/netzportal-webapp-storybook-components';
import { uploadFile } from '../../services/FileUploadService';
import { emptyEventData } from '../../store/ordersModule';
import userService from '../../services/UserService';
import DocumentsService from '../../services/DocumentsService';
import { getTranslations } from '@/helpers/globalFunctions';

export default {
  name: 'TechnicalInspection',
  components: {
    TechnicalInspectionNetzanschluss,
    TextCheckboxComponent,
    TextCheckboxActionComponent,
    ConnectionDataComponent,
    HintComponent,
    NEWNoteUploadComponent,
    NewModalDialog,
    ChargingPowerComponent,
    NewAlert,
  },
  props: {
    notEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: '',
      fileName: '',
      selectedOrder: this.$store.getters.getSelectedOrder,
      alert: false,
      alertError: false,
      alertErrorNote: false,
      deleteNote: false,
      isModalVisible: false,
      showErrors: false,
      isNoteUploadInfo: false,
      checkBoxConfig: [
        {
          title: 'technical_inspection.gis_checked',
          method: this.setgisSelected,
          checkboxName: 'gis',
          required_text: 'technical_inspection.gis_required',
          index: 4,
        },
      ],
      checkboxActionConfig: [
        {
          title: 'technical_inspection.address_checked',
          method: this.setaddressSelected,
          checkboxName: 'address',
          required_text: 'technical_inspection.address_required',
          updateComponent: 'InstallationAddressComponent',
          updateMethod: 'updateInstallationAddress',
        },
        {
          title: 'technical_inspection.customer_information_checked',
          method: this.setcustomerInformationSelected,
          checkboxName: 'customerInformation',
          required_text: 'technical_inspection.customer_information_required',
          updateComponent: 'AppplicantComponent',
          updateMethod: 'updateApplicant',
        },
        {
          title: 'technical_inspection.blueprints_checked',
          method: this.setblueprintsSelected,
          checkboxName: 'blueprints',
          required_text: 'technical_inspection.blueprints_required',
          updateComponent: 'TechnicalInspectionFileUploadComponent',
          updateMethod: 'updateFilesName',
        },
      ],
      contactInspectionDataArray: [
        {
          title: 'Rückfrage an den Kunden',
          contactCanal: 'phone',
          name: 'kunden',
          note: '',
        },
        {
          title: 'Rückfrage an das Regionalteam',
          contactCanal: 'phone',
          name: 'regionalteam',
          note: '',
        },
      ],
      showError: false,
      showNoteError: false,
      showLoading: false,
      showLoadingManuell: false,
      noteValue: { note: '' },
      alertErrorDecline: false,
      noteLabels: getTranslations('note_upload'),
    };
  },
  created() {
    this.$store.commit('resetChecksForTechnicalInspection');
    this.setChecksForTechnicalInspection();
    this.$store.commit('setLastUpdatedComponent', '');
  },
  computed: {
    includesChargingStation() {
      return !!this.selectedOrder.emobility_payload;
    },
    userPersonalData() {
      return this.$store.getters.getUserId;
    },
    technicalInspectionData() {
      return this.$store.getters.getTechnicalInspectionData;
    },
    allDataSelected() {
      return this.$store.getters.getIfAllDataSelected;
    },
    notifyEvent() {
      return this.$store.getters.getNotifyEvent
        ? this.$store.getters.getNotifyEvent
        : emptyEventData;
    },
    lastUpdatedComponent() {
      return this.$store.getters.getLastUpdatedComponent
        ? this.$store.getters.getLastUpdatedComponent
        : '';
    },
  },
  watch: {
    notifyEvent(newValue) {
      if (
        newValue.field === 'addOrderNote' ||
        newValue.field === 'updateTechnicalInspectionData'
      )
        this.alert = true;
      setTimeout(() => {
        (this.alert = false),
          this.$store.commit('setNotifyEvent', emptyEventData),
          this.$store.commit('setLastUpdatedComponent', '');
      }, 3000);
    },
    noteValue: {
      handler(newValue) {
        newValue.note
          ? (this.showNoteError = false)
          : (this.showNoteError = true);
      },
      deep: true,
    },
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.showNoteError = false;
      this.noteValue = { note: '' };
    },
    closeModalAndSaveData() {
      if (this.noteValue.note === '') {
        this.showNoteError = true;
      } else {
        const data = {
          title: 'Ablehnungsgrund',
          note: this.noteValue.note,
        };
        this.sendDeclineNote(data);
        this.isModalVisible = false;
        this.noteValue = { note: '' };
        this.showNoteError = false;
      }
    },
    sendInquiry(value) {
      if (value.note) {
        const noteObj = {
          id: this.selectedOrder.id,
          user_id: this.selectedOrder.user_id,
          note: value.note,
          title: value.title,
        };
        noteService
          .addOrderNote(noteObj, this.userPersonalData)
          .then(() => {
            this.$store.commit(
              'setLastUpdatedComponent',
              'noteContactComponent'
            );
            if (value.name) {
              this.contactInspectionDataArray.forEach((contactData) => {
                if (contactData.name === value.name) {
                  contactData.note = '';
                }
              });
            }
            this.showErrors = false;
          })
          .catch((err) => {
            this.alertError = true;
            console.error(err);
          });
      } else {
        this.showErrors = true;
      }
    },
    sendDeclineNote(value) {
      const declineNoteObj = {
        id: this.selectedOrder.id,
        user_id: this.selectedOrder.user_id,
        note: value.note,
        title: value.title,
      };
      noteService
        .addOrderNote(declineNoteObj, this.userPersonalData)
        .then(() => {
          //TODO: give feedback to user
        })
        .catch((err) => {
          this.alertErrorDecline = true;
          console.error(err);
        });
      const currentOrder = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedOrder)
      );
      userService
        .updateOrderStatus(
          {
            id: currentOrder.id,
            request_date: currentOrder.request_date,
            old_value: {
              status: currentOrder.status,
            },
            new_value: {
              status: 'ORDER_CANCELLED',
            },
            remark: value.note,
          },
          this.userPersonalData
        )
        .then((res) => {
          if (res) {
            const new_status = { status: 'ORDER_CANCELLED' };
            this.$store.commit('updateSelectedOrderData', new_status);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async sendNoteAndFile(value) {
      this.isNoteUploadInfo = true;
      const noteObj = {
        id: this.selectedOrder.id,
        note: value.note,
        title: value.title,
        filename: value.filename,
        user_id: this.selectedOrder.user_id,
      };

      this.$store.commit('setLastUpdatedComponent', 'noteUploadFileComponent');
      if (value.note) {
        try {
          const userResponse = await noteService.addOrderNote(
            noteObj,
            this.userPersonalData
          );

          // Has to get the "_persistent_event_id_" from addOrderNote; used for s3-subfoldering
          if (value.filename) {
            const url = (
              await DocumentsService.putOrderDocumentGraphQL(
                `${this.selectedOrder.id}/${userResponse.addOrderNote._persistent_event_id_}/${value.filename}`,
                process.env.VUE_APP_STAGE
              )
            ).uploadFile;
            await uploadFile(url, value.file);
            this.deleteNote = true;
          }
          this.isNoteUploadInfo = false;
          this.deleteNote = true;
        } catch (err) {
          this.isNoteUploadInfo = true;
          console.error(err);
          this.alertErrorNote = true;
        }
      }
    },
    updateNoteChangedObject(value) {
      this.$store.commit('updateNoteChangedObject', value);
    },
    setcustomerInformationSelected(value) {
      this.$store.commit('setcustomerInformationSelected', value);
    },
    setblueprintsSelected(value) {
      this.$store.commit('setblueprintsSelected', value);
    },
    setgisSelected(value) {
      this.$store.commit('setgisSelected', value);
    },
    setaddressSelected(value) {
      this.$store.commit('setaddressSelected', value);
    },
    setChecksForTechnicalInspection() {
      this.$store.commit(
        'setcustomerInformationSelected',
        !!this.selectedOrder.customer_information_checked
      );
      this.$store.commit(
        'setblueprintsSelected',
        !!this.selectedOrder.blueprints_checked
      );
      this.$store.commit('setgisSelected', !!this.selectedOrder.gis_checked);
      this.$store.commit(
        'setaddressSelected',
        !!this.selectedOrder.address_checked
      );
      this.$store.commit(
        'setconnectiondataSelected',
        !!this.selectedOrder.connection_data_checked
      );
      if (this.selectedOrder.product.includes('gas')) {
        this.$store.commit(
          'setConnectionDataGas',
          this.selectedOrder.connection_data_gas
            ? this.selectedOrder.connection_data_gas
            : emptyConnectionDataGas
        );
      }
      if (
        this.selectedOrder.product.includes('electricity') ||
        this.selectedOrder.product.includes('eMobility')
      ) {
        this.$store.commit(
          'setConnectionDataElectricity',
          this.selectedOrder.connection_data_electricity
            ? this.selectedOrder.connection_data_electricity
            : emptyConnectionDataElectricity
        );
      }
      if (this.includesChargingStation) {
        this.$store.commit(
          'setTechnicalChargingPowerSelected',
          this.selectedOrder.emobility_charging_power_checked
            ? this.selectedOrder.emobility_charging_power_checked
            : false
        );
        this.$store.commit(
          'setTechnicalChargingPowerValues',
          this.selectedOrder.emobility_charging_power_values
            ? this.selectedOrder.emobility_charging_power_values
            : emptyChargingPowerValues
        );
      }
    },
    updateTechnicalInspectionValues(confirmed) {
      const changedObject = {
        id: this.selectedOrder.id,
        request_date: this.selectedOrder.request_date,
        old_value: this.$store.getters.getSelectedOrderInspectionData,
        new_value: this.technicalInspectionData,
      };
      if (confirmed) {
        changedObject.status = 'completed';
      }
      this.$store.commit('setLastUpdatedComponent', 'saveCurrentResult');
      userService
        .updateTechnicalInspectionData(changedObject, this.userPersonalData)
        .then(() => {
          if (confirmed) {
            const new_status = { status: 'ORDER_CONFIRMED' };
            this.$store.commit('updateSelectedOrderData', new_status);
          }
        })
        .catch((err) => {
          console.error(err);
          this.alertError = true;
        });
    },
    confirmCheck() {
      if (this.allDataSelected) {
        this.updateTechnicalInspectionValues(true);
      } else {
        this.showError = true;
      }
    },
    switchToManualStatus() {
      this.showLoadingManuell = true;
      this.showError = false;
      const currentOrder = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedOrder)
      );

      userService
        .updateOrderStatus(
          {
            id: currentOrder.id,
            request_date: currentOrder.request_date,
            old_value: {
              status: currentOrder.status,
            },
            new_value: {
              status: 'ORDER_MANUAL',
            },
          },
          this.userPersonalData
        )
        .then((res) => {
          if (res) {
            const new_status = { status: 'ORDER_MANUAL' };
            this.$store.commit('updateSelectedOrderData', new_status);
            this.showLoadingManuell = false;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style scoped>
.checkbox-list {
  width: 45% !important;
}
.error_message_dialog {
  color: red;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 3px;
}
.alert_success {
  background-color: #e4f0d6 !important;
  display: flex;
  margin-top: 10px;
}

@media screen and (max-width: 1279px) and (min-width: 1024px) {
  .checkbox-list {
    width: 100% !important;
  }
}

.container--note-upload-pending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
