import axios from 'axios';
import documentsService from '@/services/DocumentsService';
import fileMethods from '@/helpers/fileMethods';
import store from '../store/index.js';
import { uploadFile } from '@/services/FileUploadService';
import { removeUUIDFromFileName } from '@/helpers/globalFunctions';

export const initPVFiles = async (pvOrder) => {
  const promises = [];

  store.commit('setPhotovoltaicFilesInitialized', false);
  store.commit('resetPVUploadFilesModule');

  for (const value of pvOrder.file_names) {
    if (value) {
      promises.push(getPhotovoltaicDocument(value, 'pv_plan', pvOrder.id));
    }
  }
  if (pvOrder.existing_systems) {
    const es_file_name = pvOrder.existing_systems?.file_name;
    if (es_file_name) {
      promises.push(getPhotovoltaicDocument(es_file_name, 'pv_es', pvOrder.id));
    }
  }
  if (pvOrder.pv_inverters) {
    pvOrder.pv_inverters.forEach(async (pv_inverter, index) => {
      if (pv_inverter.e4_certificate_file_name) {
        promises.push(
          await getPhotovoltaicDocument(
            pv_inverter.e4_certificate_file_name,
            `pv_inverter-${index + 1}`,
            pvOrder.id
          )
        );
      }
      if (pv_inverter.e6_certificate_file_name) {
        promises.push(
          getPhotovoltaicDocument(
            pv_inverter.e6_certificate_file_name,
            `pv_inverter-${index + 1}`,
            pvOrder.id
          )
        );
      }
    });
  }
  if (pvOrder.energy_storage) {
    const conformityFileName =
      pvOrder.energy_storage?.conformity_certificate_file_name;
    const protectionFileName =
      pvOrder.energy_storage?.protection_certificate_file_name;
    if (conformityFileName) {
      promises.push(
        getPhotovoltaicDocument(conformityFileName, 'pv_storage', pvOrder.id)
      );
    }
    if (protectionFileName) {
      promises.push(
        getPhotovoltaicDocument(protectionFileName, 'pv_storage', pvOrder.id)
      );
    }
  }
  if (pvOrder.finance_data) {
    const ustFileName = pvOrder.finance_data?.ust_proof_file_name;
    const assignmentFileName =
      pvOrder.finance_data?.assignment_notice_file_name;
    if (ustFileName) {
      promises.push(
        getPhotovoltaicDocument(ustFileName, 'pv_finance', pvOrder.id)
      );
    }
    if (assignmentFileName) {
      promises.push(
        getPhotovoltaicDocument(assignmentFileName, 'pv_finance', pvOrder.id)
      );
    }
  }

  if (pvOrder.counter_id_data) {
    const fileName1 = pvOrder.counter_id_data?.[0]?.file_name;
    const fileName2 = pvOrder.counter_id_data?.[1]?.file_name;
    const fileName3 = pvOrder.counter_id_data?.[2]?.file_name;
    if (fileName1) {
      promises.push(
        getPhotovoltaicDocument(fileName1, 'pv_counter', pvOrder.id)
      );
    }
    if (fileName2) {
      promises.push(
        getPhotovoltaicDocument(fileName2, 'pv_counter', pvOrder.id)
      );
    }
    if (fileName3) {
      promises.push(
        getPhotovoltaicDocument(fileName3, 'pv_counter', pvOrder.id)
      );
    }
  }

  if (pvOrder.pvrequest_inspection) {
    const technical_inspection_file_name =
      pvOrder.pvrequest_inspection?.customer_station_plan_filename;
    if (technical_inspection_file_name) {
      promises.push(
        getPhotovoltaicDocument(
          technical_inspection_file_name,
          'pv_ti',
          pvOrder.id
        )
      );
    }
  }

  if (pvOrder.new_counter_data) {
    const commissioningApplicationFileName =
      pvOrder.new_counter_data?.commissioning_application_file_name;
    if (commissioningApplicationFileName) {
      promises.push(
        getPhotovoltaicDocument(
          commissioningApplicationFileName,
          'pv_new_counter',
          pvOrder.id
        )
      );
    }
  }

  if (pvOrder.counters_to_remove_data) {
    for (const counter of pvOrder.counters_to_remove_data) {
      promises.push(
        getPhotovoltaicDocument(counter.file_name, 'pv_rm_counter', pvOrder.id)
      );
    }
  }

  await Promise.all(promises);
  store.commit('setPhotovoltaicFilesInitialized', true);
};

export const getPhotovoltaicDocument = async (fileName, fileKey, pvOrderId) => {
  await documentsService
    .getPhotovoltaicDocumentGraphQL(
      pvOrderId + '/' + fileName,
      process.env.VUE_APP_STAGE
    )
    .then(async (res) => {
      await downloadFromPresignedURL(
        res.downloadFile,
        fileName,
        fileKey,
        pvOrderId
      );
    })
    .catch((err) => {
      console.log(err);
    });
};

export const downloadFromPresignedURL = async (
  fileURL,
  fileName,
  fileKey,
  pvOrderId
) => {
  const downloadResponse = await axios({
    url: fileURL,
    method: 'GET',
    headers: {
      Accept: '*',
      'Content-Type': 'application/pdf;charset=utf-8',
    },
    responseType: 'arraybuffer',
    data: {},
  });
  const product = 'PhotovoltaicFiles';
  const position = parseInt(fileName.charAt(0));
  const id = pvOrderId + '_' + position;
  const fileObject = fileMethods.convertToBlobAndReturnFileObject(
    downloadResponse.data,
    removeUUIDFromFileName(fileName),
    product,
    id,
    fileKey
  );
  store.commit('setPhotovoltaicFileURL', fileObject);
};

export const uploadPVFiles = async (selectedFiles, extendedUUID) => {
  const fileNames = [];
  const filesForUpload = [];
  selectedFiles.forEach((fileObject) => {
    if (fileObject.file_name) {
      fileNames.push(`${fileObject.file_name}`);
      filesForUpload.push(fileObject);
      const fileURL = { url: '', imageName: fileObject.file_name };
      fileURL.url = window.URL.createObjectURL(fileObject.file);
      store.commit('setPhotovoltaicFileURL', fileURL);
    }
  });

  const PromiseArr = [];
  for (let i = 0; i < fileNames.length; i++) {
    if (extendedUUID) {
      const file_name = `${store.getters.getSelectedFeedInSystem.id}/\${uuid}/${fileNames[i]}`;
      PromiseArr.push(
        fileMethods.uploadFileGraphQL(
          file_name,
          'putPVTechnicalInspectionDocumentGraphQL',
          true
        )
      );
    } else {
      const file_name = `${store.getters.getSelectedFeedInSystem.id}/${fileNames[i]}`;
      PromiseArr.push(
        fileMethods.uploadFileGraphQL(
          file_name,
          'putPhotovoltaicDocumentGraphQL'
        )
      );
    }
  }

  const signedUrls = await Promise.all(PromiseArr).then((res) => {
    return res;
  });

  try {
    const fileUploadsPromises = [];
    let i = 0;
    for (const signedUrl of signedUrls) {
      fileUploadsPromises.push(uploadFile(signedUrl, filesForUpload[i].file));
      i++;
    }

    return Promise.all(fileUploadsPromises).catch((e) => {
      console.log(e);
      console.log(`Could not upload files ${fileNames}`);
      throw e;
    });
  } catch (e) {
    console.log(e);
    console.log(`Could not upload files ${fileNames}`);
  }
};
