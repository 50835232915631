<template>
  <div>
    <div
      v-if="selectedEmobility && Object.keys(selectedEmobility).length !== 0"
      :key="selectedEmobility.id"
    >
      <div class="page_title">Infrastruktur E-Mobilität</div>
      <NewGlassCard class="card-without-title default-mb" title="">
        <div class="header_container">
          <v-btn class="back-button" @click="goOnePageBack()"
            ><v-icon color="#6E6E6E">mdi-arrow-left</v-icon></v-btn
          >
          <div class="item_container">
            <div
              v-html="this.selectedEmobilityApplicant"
              class="company_data"
            ></div>
            <div
              v-html="this.selectedEmobilityAdress"
              class="company_address"
            ></div>
          </div>
          <v-btn
            rounded
            class="ml-10 confirm-order-button"
            :color="translateEmobilityStatus[1]"
          >
            {{ translateEmobilityStatus[0] }}
          </v-btn>
          <div class="start-exam-button">
            <v-btn
              v-show="selectedEmobility.status === 'ORDER_CREATED'"
              class="mr-4"
              @click="changeStatus('ORDER_TECHNICAL_FEASIBILITY')"
              color="primary"
            >
              Prüfung starten
            </v-btn>
            <v-btn
              v-show="
                selectedEmobility.status === 'ORDER_CONFIRMED' ||
                selectedEmobility.status === 'ORDER_CANCELLED'
              "
              class="mr-4"
              @click="changeStatus('ORDER_TECHNICAL_FEASIBILITY')"
              color="primary"
            >
              {{ $t('header_details.check_again') }}
            </v-btn>
          </div>
        </div>
      </NewGlassCard>

      <NewGlassCard
        v-if="selectedEmobility.status === 'ORDER_TECHNICAL_FEASIBILITY'"
        class="default-mb"
        :title="$t('technical_inspection.title')"
      >
        <emobility-inspection-component @show-modal="showModal($event)" />
      </NewGlassCard>

      <NewGlassCard
        title="Technische Prüfung für E-Mobilität"
        class="default-mb"
        v-if="selectedEmobility.status === 'ORDER_CONFIRMED'"
      >
        <emobility-inspection-component
          :notEditable="true"
          v-if="showEmobilityInspectionresult"
          @closeTechnicalInspection="showEmobilityInspectionresult = false"
        />
        <single-raw
          v-else
          :title="formatDate(this.historyDate)"
          class="single-raw"
          :notEditable="true"
          @edit="showEmobilityInspectionresult = true"
        >
          <div class="technical_inspection_container">
            <div>{{ $t('details_page.technical_inspection_confirmed') }}</div>
            <v-icon color="green" x-large class="icon">mdi-check-bold</v-icon>
          </div>
        </single-raw>
      </NewGlassCard>

      <NewGlassCard
        id="emobility-details"
        :title="$t('emobility_details.title')"
        class="default-mb z-index-0"
      >
        <emobility-details
          :file_names="selectedFiles"
          @show-modal="showModal($event)"
        />
      </NewGlassCard>

      <div :class="this.$vuetify.breakpoint.xl ? 'xl-design-details-page' : ''">
        <NewGlassCard
          :class="
            this.$vuetify.breakpoint.xl
              ? 'xl-design-details-page-element default-mb ml-2'
              : 'default-mb'
          "
          class="z-index-0"
          :title="$t('history.title')"
        >
          <history-table :historyType="'Emobility'"></history-table>
        </NewGlassCard>
        <NewGlassCard
          :class="
            this.$vuetify.breakpoint.xl
              ? 'xl-design-details-page-element default-mb ml-2'
              : 'default-mb'
          "
          class="z-index-0"
          :title="$t('document_history.title')"
        >
          <document-history-component :historyType="'Emobility'" />
        </NewGlassCard>
      </div>

      <NewModalDialog
        v-if="isModalVisible"
        :value="noteValue"
        role="dialog"
        @update-note-data="updateNoteChangedObject"
        @cancel-data="closeModal"
      >
        <template v-slot:body>
          <dynamic-component class="body-style" :type="currentComponent" />
          <div
            v-if="showModalUpdateError"
            style="width: fit-content"
            class="error-dialog ml-8"
          >
            {{ $t('feed_in_system_details.saving_failed_please_reload') }}
          </div>
        </template>
        <template v-slot:footer>
          <div v-show="showSpinner" class="update-event-spinner">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            />
          </div>
          <div v-if="!showSpinner">
            <button
              type="button"
              class="btn-save"
              @click="closeModalAndSaveData"
            >
              Speichern
            </button>
            <button type="button" class="btn-cancel" @click="closeModal">
              abbrechen
            </button>
          </div>
          <v-alert
            type="error"
            class="alert_error ml-4"
            v-if="alert"
            text
            transition="scale-transition"
          >
            Speicherung fehlgeschlagen
          </v-alert>
        </template>
      </NewModalDialog>
    </div>
    <div v-if="isServerError">
      <h1>
        {{ $t('emobility_details.loading_error') }}
      </h1>
      <p>
        {{ $t('emobility_details.error_hint') }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  NewGlassCard,
  NewModalDialog,
} from '@newnetzportal/netzportal-webapp-storybook-components';
import EmobilityDetails from '../components/emobility-details/EmobilityDetails';
import DynamicComponent from '../components/order-steps-components/DynamicDialogBody';
import emobilityService from '../services/EmobilityService';
import { EventBus } from '../main';
import HistoryTable from '../components/history-table/HistoryTable';
import EmobilityInspectionComponent from '../components/emobility-inspection-component/EmobilityInspectionComponent.vue';
import gql from 'graphql-tag';
import SingleRaw from '../components/order-details/SingleRaw';
import isEqual from 'lodash.isequal';
import { getEmobilityById } from '../services/EmobilityService';
import DocumentHistoryComponent from '../components/order-details/DocumentHistoryComponent';
import {
  personalDataObject,
  installationAddressObject,
} from '../helpers/transformToPresentableData';

export default {
  name: 'EMobilityDetailsPage',
  components: {
    NewGlassCard,
    NewModalDialog,
    EmobilityDetails,
    DynamicComponent,
    HistoryTable,
    EmobilityInspectionComponent,
    SingleRaw,
    DocumentHistoryComponent,
  },
  data() {
    return {
      isModalVisible: false,
      noteValue: { note: '' },
      alert: false,
      showEmobilityInspectionresult: false,
      emobility_id: this.$route.params.id ? this.$route.params.id : '',
      isServerError: false,
    };
  },
  async created() {
    // for Firefox
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty(
        '--scroll-y',
        `${window.scrollY}px`
      );
    });
    if (this.emobility_id) {
      await this.getEmobilityFromBackend(this.emobility_id);
    }
  },
  computed: {
    showSpinner: {
      get() {
        return this.$store.getters.getShowSpinnerInModal;
      },
      set(value) {
        this.$store.commit('setShowSpinnerInModal', value);
      },
    },
    showModalUpdateError: {
      get() {
        return this.$store.getters.getShowModalUpdateError;
      },
      set(value) {
        this.$store.commit('setShowModalUpdateError', value);
      },
    },
    selectedEmobility() {
      return this.$store.getters.getSelectedEmobility;
    },
    selectedEmobilityApplicant() {
      return (
        this.$t('emobility_details.customer') +
        personalDataObject(this.$store.getters.getSelectedEmobility.applicant)
      );
    },
    selectedEmobilityAdress() {
      return (
        this.$t('emobility_details.address') +
        installationAddressObject(this.$store.getters.getSelectedEmobility)
      );
    },
    userPersonalData() {
      return this.$store.getters.getUserId;
    },
    historyDate() {
      return this.$store.getters.getEmobilityHistoryData
        ? this.$store.getters.getEmobilityHistoryData[0]
          ? this.$store.getters.getEmobilityHistoryData[0].date
          : null
        : null;
    },
    translateEmobilityStatus() {
      let translateEmobilityStatus = [];
      if (this.selectedEmobility.status === 'ORDER_CREATED') {
        return (translateEmobilityStatus = ['neu', '#d79bb8']);
      }
      if (this.selectedEmobility.status === 'ORDER_TECHNICAL_FEASIBILITY') {
        return (translateEmobilityStatus = ['techn. Prüfung', '#CF8AA9']);
      }
      if (this.selectedEmobility.status === 'ORDER_CONFIRMED') {
        return (translateEmobilityStatus = ['Auftrag bestätigt', '#C7799A']);
      }
      if (this.selectedEmobility.status === 'ORDER_CANCELLED') {
        return (translateEmobilityStatus = ['Auftrag abgelehnt', '#525252']);
      }

      return translateEmobilityStatus;
    },
    selectedFiles() {
      const file_names = this.selectedEmobility.uploaded_file_names_cadastral;
      const filesObject = {
        cadastral_1: null,
        cadastral_2: null,
      };
      if (file_names) {
        filesObject.cadastral_1 = file_names[0];
        filesObject.cadastral_2 = file_names[1];
      }
      return filesObject;
    },
  },
  methods: {
    formatDate(date) {
      return (
        new Date(date).toLocaleDateString('de', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }) +
        ' ' +
        new Date(date).toLocaleString('de', {
          hour: '2-digit',
          minute: '2-digit',
        })
      );
    },
    getEmobilityFromBackend(id) {
      getEmobilityById(id)
        .then((res) => {
          const currentEmobility = res.getEmobilityById;
          this.$store.commit('setSelectedEmobility', currentEmobility);
          this.$store.commit(
            'setEmobilityAdditionalContactPersonState',
            !!currentEmobility.additional_contact_person
          );
        })
        .catch((err) => {
          console.log(err);
          this.isServerError = true;
        });
    },
    goOnePageBack() {
      const previousPage = this.$store.getters.getPreviousPage;
      if (previousPage) {
        this.$router.push(previousPage);
      } else {
        this.$router.push('/');
      }
    },
    showModal(data) {
      this.currentComponent = data.component;
      this.backendMethod = data.method;

      document.body.classList.add('modal-open');
      this.disableScrolling();
      this.noteValue.note = '';
      this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
      this.updateNoteChangedObject('');
      this.isModalVisible = true;
      this.showSpinner = false;
      this.showModalUpdateError = false;
    },
    closeModal() {
      // delete changed_object values in vuex
      this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
      this.noteValue.note = '';
      this.updateNoteChangedObject('');

      document.body.classList.remove('modal-open');
      this.enableScrolling();
      this.isModalVisible = false;
      this.alert = false;
      this.showSpinner = false;
      this.showModalUpdateError = false;
    },
    closeModalAndSaveData() {
      if (this.$store.getters.getCurrentComponentStatus === 'finished') {
        this.showSpinner = true;
        if (
          isEqual(
            this.$store.getters.getChangedObject,
            this.$store.getters.getOldValueObject
          ) &&
          (this.backendMethod !== 'updateEmobilityFilesName' ||
            (this.backendMethod === 'updateEmobilityFilesName' &&
              !this.$store.getters.getEmobFileChanged))
        ) {
          EventBus.$emit(`show-same-data-errors-${this.currentComponent}`);
          this.showSpinner = false;
          this.showModalUpdateError = false;
        } else {
          switch (this.backendMethod) {
            case 'updateEmobilityFilesName':
              EventBus.$emit('start-emobility-file-upload');
              break;
            default:
              break;
          }

          const requestBody = {
            id: this.selectedEmobility.id,
            request_date: this.selectedEmobility.request_date,
            new_value: this.$store.getters.getChangedObject,
            old_value: this.$store.getters.getOldValueObject,
            note: this.$store.getters.getNoteChangedObject,
          };
          this.uploadChangesToBackend(requestBody);
        }
      } else {
        EventBus.$emit(`show-errors-${this.currentComponent}`);
      }
    },
    uploadChangesToBackend(changedObject) {
      emobilityService[this.backendMethod](changedObject, this.userPersonalData)
        .then(() => {
          this.$store.commit(
            'updateSelectedEmobilityData',
            changedObject.new_value
          );
          document.body.classList.remove('modal-open');
          this.enableScrolling();
          this.isModalVisible = false;
          this.noteValue.note = '';
          this.showSpinner = false;
          this.showModalUpdateError = false;
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.alert = true;
            this.showSpinner = false;
            this.showModalUpdateError = true;
          }, 3000);
        });
    },
    updateNoteChangedObject(value) {
      this.$store.commit('updateNoteChangedObject', value);
    },
    disableScrolling() {
      const scrollY =
        document.documentElement.style.getPropertyValue('--scroll-y');
      document.getElementById('emobility-details').style.zIndex = 1;
      const body = document.body;
      body.style.position = 'fixed';
      body.style.width = '-webkit-fill-available';
      body.style.top = `-${scrollY}`;
    },
    enableScrolling() {
      document.getElementById('emobility-details').style.zIndex = 0;
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    },
    getChangedData() {
      const currentEmobility = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedEmobility)
      );
      const status = { status: currentEmobility.status };
      return status;
    },
    getChangedValue(valueName, responseObject) {
      let obj = null;
      if (responseObject[valueName]) {
        obj = JSON.parse(responseObject[valueName]);
      } else if (responseObject.workflow_payload) {
        const tmp = JSON.parse(responseObject.workflow_payload);
        valueName === 'new_value'
          ? (obj = { status: tmp.new_status })
          : (obj = { status: tmp.old_status });
      }
      return obj;
    },
    changeStatus(newStatus) {
      this.changedObject = this.getChangedData();
      this.$store.commit('setChangedObject', {
        status: newStatus,
      });
      this.$store.commit(
        'setOldValueObject',
        JSON.parse(JSON.stringify(this.changedObject))
      );

      const requestBody = {
        id: this.selectedEmobility.id,
        request_date: this.selectedEmobility.request_date,
        new_value: this.$store.getters.getChangedObject,
        old_value: this.$store.getters.getOldValueObject,
      };
      emobilityService
        .updateEmobilityStatus(requestBody, this.userPersonalData)
        .then((res) => {
          if (res.updateEmobilityStatus) {
            this.$store.commit(
              'updateSelectedEmobilityData',
              JSON.parse(res.updateEmobilityStatus.new_value)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        }),
        this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
    },
  },
  apollo: {
    $subscribe: {
      tags: {
        query: gql`
          subscription onEmobilityHistoryChanged($emobilityId: String!) {
            onEmobilityHistoryChanged(id: $emobilityId) {
              id
              changed_by
              event_origin
              created
              field
              title
              old_value
              new_value
              workflow_payload
              note
              status
              filename
            }
          }
        `,
        variables() {
          return {
            emobilityId: this.emobility_id,
          };
        },
        result({ data }) {
          const payload = {
            id: data.onEmobilityHistoryChanged.id,
            new_value: this.getChangedValue(
              'new_value',
              data.onEmobilityHistoryChanged
            ),
            old_value: this.getChangedValue(
              'old_value',
              data.onEmobilityHistoryChanged
            ),
            title: data.onEmobilityHistoryChanged.title,
            note: data.onEmobilityHistoryChanged.note,
            filename: data.onEmobilityHistoryChanged.filename,
            status: JSON.parse(data.onEmobilityHistoryChanged.status),
          };
          data.onEmobilityHistoryChanged.payload = JSON.stringify(payload);
          payload.new_value
            ? this.$store.commit(
                'updateSelectedEmobilityData',
                payload.new_value
              )
            : '';
          this.$store.commit(
            'updateLastChangedByInSelectedEmobility',
            data.onEmobilityHistoryChanged.changed_by
          );
          this.$store.commit(
            'updateLastUpdateInSelectedEmobility',
            data.onEmobilityHistoryChanged.created
          );
          this.$store.commit('setNotifyEvent', data.onEmobilityHistoryChanged);
          EventBus.$emit(
            'update-history-table-emobility',
            data.onEmobilityHistoryChanged
          );
        },
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('resetEmobilityFileAndFileURLs');
    next();
  },
};
</script>

<style scoped lang="scss">
.page_title {
  font-size: 60px;
}
.card-without-title > .frosted_glass_card_title {
  margin-bottom: 0px !important;
}
.header_container {
  display: flex;
  align-items: center;
}
.item_container {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.company_data {
  font-size: 25px;
}
.company_address {
  font-size: 20px;
}
.back-button {
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0;
  height: 35px !important;
  width: 90px;
  box-shadow: none;
  margin-right: 30px;
}
.z-index-0 {
  z-index: 0;
}
.confirm-order-button {
  color: white !important;
  box-shadow: none;
  pointer-events: none;
}
.start-exam-button {
  width: 40%;
  justify-content: flex-end;
  display: flex;
}
</style>
