import GraphQLService from './GraphQLService';

export async function getInstallersWithFilter(requestBody) {
  return GraphQLService.requestWithOpenID(
    `
      query getInstallersWithFilter($limit: Int, $from_index: Int, $status: String, $energy_type: String, $search_word: String, $expiry: String, $internal: String) { 
        getInstallersWithFilter ( limit: $limit, from_index: $from_index, status: $status, energy_type: $energy_type, search_word: $search_word, expiry: $expiry, internal: $internal  ) {
          items {
            id
            user_id
            company_data {
              company_name
              email
              phone_number
              troubleshooting_number
              company_address {
                country
                city
                street
                street_number
                zip
              }
            }
            expiry
            concession_division_en
            request_date
            status
            badge_id
            internal
            presentable_badge_id
            last_update
            last_changed_by
          }
          count_orders
        }
      }`,
    {
      limit: requestBody.limit,
      from_index: requestBody.from_index,
      status: requestBody.status,
      energy_type: requestBody.energy_type,
      search_word: requestBody.search_word,
      expiry: requestBody.expiry,
      internal: requestBody.internal,
    }
  );
}

export async function getInstallersSummary() {
  return GraphQLService.requestWithOpenID(
    `
      query getInstallersSummary { 
        getInstallersSummary {
          count_installers
          license_request_created
          license_request_checking
          license_request_active
          license_request_expired
          license_request_refused
          license_request_active_recheck
        }
      }`
  );
}

export async function getInstallersEnergytypeSummary() {
  return GraphQLService.requestWithOpenID(
    `
      query getInstallersEnergytypeSummary { 
        getInstallersEnergytypeSummary {
          count_installers
          installers_gas
          installers_water
          installers_electricity
        }
      }`
  );
}

export async function getInstallerById(orderId) {
  return GraphQLService.requestWithOpenID(
    `
      query getInstallerById { 
        getInstallerById (id: "${orderId}") {
            id
            user_id
            badge_id
            presentable_badge_id
            badge_filenames
            company_data {
              company_name
              email
              phone_number
              troubleshooting_number
              company_address {
                country
                city
                street
                street_number
                zip
              }
            }
            file_names {
              employment_contract
              business_registration
              craft_role
              public_liability_insurance
              commercial_register
              gas_water_file_name
              electricity_file_name
              master_certificate_1
              master_certificate_2
              master_certificate_3
              master_certificate_4
              master_certificate_5
              water_technical_certificate_1
              water_technical_certificate_2
              water_technical_certificate_3
              water_technical_certificate_4
              water_technical_certificate_5
              electricity_technical_certificate_1
              electricity_technical_certificate_2
              electricity_technical_certificate_3
              electricity_technical_certificate_4
              electricity_technical_certificate_5
              gas_technical_certificate_1
              gas_technical_certificate_2
              gas_technical_certificate_3
              gas_technical_certificate_4
              gas_technical_certificate_5
              concession_gas
              concession_electricity
              concession_water
            }
            contact_person {
              function_in_company
              surname
              name
              phone_number
              mobile_number
              title
              email
            }
            concession_division
            concession_division_en
            request_date
            status
            concession_division_de
            company_data_checked_electricity
            responsible_specialists_checked_electricity
            documents_checked_electricity
            professional_competence_checked_electricity
            company_data_checked_gas_water
            responsible_specialists_checked_gas_water
            documents_checked_gas_water
            professional_competence_checked_gas_water
            request_date_de
            responsible_specialists {
              function_in_company
              energy_types
              surname
              name
              phone_number
              mobile_number
              title
              email
              energy_types_de
            }
            requirements {
              electricity_installations
              gas_water_installations
            }
            internal
            expiry_date_electricity
            expiry_date_gas
            expiry_date_water
            update_expiry_dates
        }
      }`
  );
}

export async function getInstallersExpiryDateSummary() {
  return GraphQLService.requestWithOpenID(
    `
      query getInstallersExpiryDateSummary { 
        getInstallersExpiryDateSummary {
          count_installers
          expiry_2021
          expiry_2022
        }
      }`
  );
}

export async function getInstallersInternalSummary() {
  return GraphQLService.requestWithOpenID(
    `
      query getInstallersInternalSummary { 
        getInstallersInternalSummary {
          count_installers
          installers_internal
          installers_external
        }
      }`
  );
}
