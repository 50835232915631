import { render, staticRenderFns } from "./DocumentHistoryComponent.vue?vue&type=template&id=8625fc00&scoped=true"
import script from "./DocumentHistoryComponent.vue?vue&type=script&lang=js"
export * from "./DocumentHistoryComponent.vue?vue&type=script&lang=js"
import style0 from "./DocumentHistoryComponent.vue?vue&type=style&index=0&id=8625fc00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8625fc00",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAlert,VBtn,VDialog,VIcon,VImg})
