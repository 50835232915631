<template>
  <div>
    <edit-document-component
      @maximize-image="maximizeImage"
      @edit-document="downloadBlob"
      @send-document="deliverInstallerBadge"
      :showMailButton="showMailButton"
      :showMaximazeButton="showMaximazeButton"
    >
      <template v-slot:component-content>
        <div style="text-align: center">
          {{ $t('download_id.id_card_number') }}:
          {{ selectedInstaller.presentable_badge_id }}
        </div>
      </template>
    </edit-document-component>

    <v-dialog max-width="720px" v-model="dialog">
      <v-btn @click="dialog = false" icon class="dialog--btn-close">
        <v-icon color="primary" size="40pt">mdi-close</v-icon>
      </v-btn>
      <div>
        <template v-if="!isError">
          <VuePdfEmbed :source="imgSrc" />
        </template>
        <template v-else>
          <NewAlert color="ERROR" :message="$t('download_id.error')" />
        </template>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import documentsService from '../../services/DocumentsService';
import axios from 'axios';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import UserService from '../../services/UserService';
import fileMethods from '../../helpers/fileMethods';
import {
  NewAlert,
  EditDocumentComponent,
} from '@newnetzportal/netzportal-webapp-storybook-components';

export default {
  name: 'DownloadIdComponent',
  components: {
    EditDocumentComponent,
    VuePdfEmbed,
    NewAlert,
  },
  created() {
    if (this.fileName) {
      this.getInstallerId();
    }
  },
  props: {
    fileName: {
      type: String,
    },
  },
  data() {
    return {
      imgSrc: '',
      dialog: false,
      isError: false,
      showMailButton: true,
      showMaximazeButton: true,
    };
  },
  computed: {
    selectedInstaller() {
      return this.$store.getters.getSelectedInstaller;
    },
    userPersonalData() {
      return this.$store.getters.getUserId;
    },
  },
  methods: {
    getInstallerId() {
      documentsService
        .getInstallerDocumentGraphQL(
          this.selectedInstaller.id + '/attachments/' + this.fileName,
          process.env.VUE_APP_STAGE
        )
        .then(async (res) => {
          this.downloadFromPresignedURL(res.downloadFile);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async downloadBlob() {
      await this.getInstallerId();
      fileMethods.downloadFile(this.fileName, 'Order');
    },
    async downloadFromPresignedURL(fileURL) {
      const downloadResponse = await axios({
        url: fileURL,
        method: 'GET',
        headers: {
          Accept: '*',
          'Content-Type': 'application/pdf;charset=utf-8',
        },
        responseType: 'arraybuffer',
      });
      const product = 'order';
      const id = `${this.selectedInstaller.id}_${this.fileName.substring(
        0,
        this.fileName.indexOf('_')
      )}`;
      const fileObject = fileMethods.convertToBlobAndReturnFileObject(
        downloadResponse.data,
        this.fileName,
        product,
        id,
        'id_card'
      );
      this.$store.commit('setOrderFileURL', fileObject);
      this.showLoader = true;
      this.isError = false;
      try {
        const res = await this.showFilePreview();
        this.imgSrc = res;
      } catch (err) {
        this.isError = true;
        console.error(err);
      }
    },
    showFilePreview() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
          try {
            const response = event.target.result;
            resolve(response);
          } catch (err) {
            this.showLoader = false;
            reject(err);
          }
        };
        reader.onerror = (error) => {
          this.showLoader = false;
          reject(error);
        };
        reader.readAsDataURL(
          this.$store.getters.getOrderFileFromBackend('id_card')
        );
      });
    },
    async maximizeImage() {
      await this.getInstallerId();
      this.dialog = true;
    },
    deliverInstallerBadge() {
      const changedObject = {
        id: this.selectedInstaller.id,
        request_date: this.selectedInstaller.request_date,
        user_id: this.userPersonalData,
      };
      UserService.deliverInstallerBadge(changedObject, this.userPersonalData)
        .then(() => {
          //TODO: give feedback to user
        })
        .catch((err) => {
          this.$emit('deliver-badge-error');
          console.error(err);
        });
    },
  },
};
</script>

<style scoped>
.frist_icon_container {
  background-color: #dddddd;
  padding: 16px;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  align-items: center;
}
.id_container {
  display: flex;
  border-radius: 8px;
  background-color: #f4f4f4;
  color: #6e6e6e;
  font-size: 18px;
}
.icon_container {
  background-color: #dddddd;
  padding: 16px;
  border-left: 1px solid #6e6e6e;
  border-right: 1px solid #6e6e6e;
  display: flex;
}
.last_icon_container {
  background-color: #dddddd;
  padding: 16px;
  border-radius: 0px 8px 8px 0px;
  display: flex;
}
.title_container {
  padding: 0px 16px;
  width: 75%;
  display: flex;
  flex-direction: column;
}

.dialog--btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
