<template>
  <div>
    <div class="page_title">Installateur</div>
    <NewGlassCard :title="'Installateurausweise'">
      <template v-slot:back-button>
        <v-btn class="back-button mb-6" @click="goOnePageBack()"
          ><v-icon color="#6E6E6E">mdi-arrow-left</v-icon></v-btn
        >
      </template>
      <div>
        <installers-overview-component />
      </div>
    </NewGlassCard>
  </div>
</template>

<script>
import { NewGlassCard } from '@newnetzportal/netzportal-webapp-storybook-components';
import InstallersOverviewComponent from '../components/installers-overview-component/InstallersOverviewComponent';

export default {
  name: 'InstallersPage',
  components: {
    NewGlassCard,
    InstallersOverviewComponent,
  },
  methods: {
    goOnePageBack() {
      const previousPage = this.$store.getters.getPreviousPage;
      if (previousPage) {
        this.$router.push(previousPage);
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style scoped>
.page_title {
  font-size: 60px;
}
.back-button {
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  height: 35px !important;
  width: 90px;
  box-shadow: none;
  margin-right: 30px;
}
</style>
