<template>
  <div>
    <div class="heading">Aufträge Netzanschluss</div>
    <filter-product-button :valuesList="valuesList" />
    <NewGlassCard :title="this.translateFilterValue">
      <template v-slot:back-button>
        <v-btn class="back-button mb-6" @click="goOnePageBack()"
          ><v-icon color="#6E6E6E">mdi-arrow-left</v-icon></v-btn
        >
      </template>
      <clerk-orders-overview-component />
    </NewGlassCard>
  </div>
</template>

<script>
import { NewGlassCard } from '@newnetzportal/netzportal-webapp-storybook-components';
import ClerkOrdersOverviewComponent from '../components/clerk-orders-overview/ClerkOrdersOverviewComponent';
import FilterProductButton from '../components/design-components/FilterProductButton';
import orderService from '../services/OrderService';

export default {
  name: 'ConnectionPage',
  components: {
    ClerkOrdersOverviewComponent,
    NewGlassCard,
    FilterProductButton,
  },
  created() {
    this.getOrdersSummaryFromBackend();
  },
  data() {
    return {
      ordersSummary: [],
    };
  },
  computed: {
    journeyFilter() {
      return this.$store.state.orders.journeyFilter;
    },
    translateFilterValue() {
      let translateFilterValue = '';
      if (this.journeyFilter === 'eMobility') {
        return (translateFilterValue = 'Netzanschluss E-Mobilität');
      }
      if (this.journeyFilter === 'einfamilienhaus') {
        return (translateFilterValue = 'Netzanschluss Einfamilienhaus');
      }
      if (this.journeyFilter === 'alle') {
        return (translateFilterValue = 'Alle Netzanschlüsse');
      }
      return translateFilterValue;
    },
    valuesList() {
      return [
        {
          label: 'Alle Netzanschlüsse',
          id: 'alle',
          value: this.countOrdersAll,
        },
        {
          label: 'Netzanschluss Einfamilienhaus',
          id: 'einfamilienhaus',
          value: this.countOrdersEfh,
        },
        {
          label: 'Netzanschluss E-Mobilität',
          id: 'eMobility',
          value: this.countOrdersEMob,
        },
      ];
    },
    countOrdersAll() {
      return this.ordersSummary.count_orders;
    },
    countOrdersEfh() {
      return this.ordersSummary.count_orders_einfamilienhaus;
    },
    countOrdersEMob() {
      return this.ordersSummary.count_orders_eMobility;
    },
  },
  methods: {
    goOnePageBack() {
      const previousPage = this.$store.getters.getPreviousPage;
      if (previousPage) {
        this.$router.push(previousPage);
      } else {
        this.$router.push('/');
      }
    },
    getOrdersSummaryFromBackend() {
      orderService
        .getOrdersSummary('')
        .then((res) => {
          this.ordersSummary = res.getOrdersSummary;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.heading {
  font-size: 50px;
  margin-bottom: 0;
}
.back-button {
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  height: 35px !important;
  width: 90px;
  box-shadow: none;
  margin-right: 30px;
}
</style>
