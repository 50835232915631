<template>
  <v-app>
    <nav-bar />

    <v-main class="page_background" style="padding: ">
      <router-view></router-view>
      <div class="floating-container">
        <NewSupportFlow
          @executeLogin="loginToNew()"
          :isAuthenticated="isAuthenticated"
          :userData="userData"
          :postUrl="postURL"
          :isStepperOpen="isStepperOpen"
          :stepValue="stepValue"
          @update-step-value="stepValue.step_count = $event"
          @openStepper="isStepperOpen = true"
          @closeStepper="closeSupportFlow()"
          :webappLabel="'netzportal-webapp-backoffice'"
          :widthDialog="windowDimensions"
          :fabButtonSize="40"
        />
      </div>
      <NotificationsComponent />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/menu-component/NavBar';
import auth from './cognito-auth/cognitoAuth';
import { NewSupportFlow } from '@newnetzportal/netzportal-webapp-storybook-components';
import NotificationsComponent from './components/notifications/NotificationsComponent.vue';

export default {
  components: {
    NavBar,
    NewSupportFlow,
    NotificationsComponent,
  },
  name: 'App',
  data() {
    return {
      hydrated: false,
      isAuthenticated: false,
      isStepperOpen: false,
      postURL: process.env.VUE_APP_SUPPORT_FILE_UPLOAD,
    };
  },
  created() {
    //loginNew.init("netzportal_backoffice_portal", process.env.VUE_APP_BASE_URL);
    this.checkAuthenticationForSupportStep();
  },
  methods: {
    loginToNew() {
      auth.auth.getSession();
    },
    updateStepValue(value) {
      this.$store.commit('setSupportStepValue', value);
    },
    async checkAuthenticationForSupportStep() {
      try {
        this.isAuthenticated = auth.auth.isUserSignedIn();

        if (this.isAuthenticated) {
          this.$store.commit('setSupportStepValue', 3);
        } else {
          this.$store.commit('setSupportStepValue', 1);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error: ', error);
      }
    },
    closeSupportFlow() {
      this.isStepperOpen = false;
      if (this.isAuthenticated) {
        this.$store.commit('setSupportStepValue', 3);
      } else {
        this.$store.commit('setSupportStepValue', 1);
      }
    },
  },
  computed: {
    userData() {
      return this.$store.getters.getUserInfo;
    },
    windowDimensions() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '350px';
        case 'sm':
          return '500px';
        case 'md':
          return '500px';
        default:
          return '600px';
      }
    },
    stepValue: {
      get() {
        return this.$store.getters['getSupportStepValue'];
      },
      set(newValue) {
        this.$store.commit('setSupportStepValue', newValue);
      },
    },
  },
  async mounted() {
    await this.$apollo.provider.defaultClient.hydrated();
    this.hydrated = true;

    if (localStorage.ip_openStepper) {
      this.isStepperOpen = localStorage.ip_openStepper === 'true';
    } else {
      localStorage.ip_openStepper = this.isStepperOpen;
    }
  },
  watch: {
    isStepperOpen(newValue) {
      localStorage.ip_openStepper = newValue;
    },
  },
};
</script>

<style scoped>
.floating-container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 45px 25px;
}
</style>
