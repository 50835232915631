<template>
  <div>
    <div class="dashboard-and-order-number-search-container mb-10">
      <div class="dashboard-icon-and-text">
        <v-icon size="60" color="white">mdi-view-dashboard</v-icon>
        <p>{{ $t('navigation_bar.dashboard') }}</p>
      </div>
      <div class="frosted_glass_card">
        <SearchFieldWithSuggestions
          class="search-input"
          :labels="searchfieldLabels"
          :hasPrefix="true"
          :items="orderNumbersList"
          itemValueIdentifier="id"
          :itemTextIdentifier="formatCaseNumber"
          @searchFieldValueUpdate="searchFieldValueHandler"
          @searchValueUpdate="searchInputChangeHandler"
          :isLoading="!areOrderNumbersReady"
        />
      </div>
    </div>
    <DashboardComponent
      class="default-mb"
      :dataOrderObject="dataOrderObject"
      :dataInstallerEnergyTypeObject="dataInstallerEnergyTypeObject"
      :dataEMobilityObject="dataEMobilityObject"
      :dataReady="dataReady"
      :dataReadyInstallerEnergyType="dataReadyInstallerEnergyType"
      :dataReadyEMobility="dataReadyEMobility"
      :dataReadyInstallersExpiry="false"
      :dataInstallerObject="dataInstallerObject"
      :dataReadyInstallers="dataReadyInstallers"
      :dataFeedInSystemsObject="dataFeedInSystemsObject"
      @show-filtered-details="
        $router.push({
          name: 'ConnectionPage',
          query: {
            page: 1,
            journey: $event,
            order_status: ['ORDER_CREATED', 'ORDER_TECHNICAL_FEASIBILITY'],
            rt: 'all',
          },
        })
      "
      @show-details="
        $router.push({
          name: 'ConnectionPage',
          query: { page: 1, journey: 'all', status: 'all', rt: 'all' },
        })
      "
      @show-details-installers="
        $router.push({
          name: 'InstallersPage',
          query: { page: 1, status: 'all', energy_type: 'all' },
        })
      "
      @show-filtered-details-installers="
        $router.push({
          name: 'InstallersPage',
          query: {
            page: 1,
            status: 'all',
            energy_type: 'all',
            internal: $event ? 'true' : 'false',
            expiry: 'all',
          },
        })
      "
      @show-filtered-details-installers-energy-type="
        $router.push({
          name: 'InstallersPage',
          query: { page: 1, status: 'all', energy_type: $event, expiry: 'all' },
        })
      "
      @show-details-emobility="
        $router.push({
          name: 'EMobilityPage',
          query: { page: 1, status: 'all', rt: 'all' },
        })
      "
      @show-filtered-details-emobility="
        $router.push({
          name: 'EMobilityPage',
          query: {
            page: 1,
            status: ['ORDER_CREATED', 'ORDER_TECHNICAL_FEASIBILITY'],
            rt: 'all',
          },
        })
      "
      @show-filtered-details-feed-in-systems="
        redirectToSelectedPVJourneyNewOrders($event)
      "
    >
    </DashboardComponent>

    <div class="default-mb">
      <div class="service-container">
        <NewGlassCard
          :class="
            $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
              ? 'frosted_glas'
              : 'frosted_glas mb-2'
          "
          :title="$t('more_services.title')"
        >
          <div class="grid-style">
            <a
              class="white-text"
              href="https://www.new-netz.de/technische-richtlinien/"
              target="_blank"
              >Technische Richtlinien Kundenanlage</a
            >
            <a
              class="white-text"
              href="https://www.new-netz.de/fuer-energie-einspeiser/technische-richtlinien/"
              target="_blank"
              >Technische Richtlinien Erzeugungsanlagen</a
            >
          </div>
        </NewGlassCard>
        <NewGlassCard :title="$t('gis_service.title')" class="frosted_glas">
          <a class="white-text" href="https://gis.geo.new.de" target="_blank"
            >Link zum GIS Service</a
          >
          <div class="icon-container">
            <map-pointer-icon class="map_pointer_icon" />
            <map-icon />
          </div>
        </NewGlassCard>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DashboardComponent,
  SearchFieldWithSuggestions,
  NewGlassCard,
} from '@newnetzportal/netzportal-webapp-storybook-components';
import orderService from '../services/OrderService';
import MapPointerIcon from '../icons/MapPointerIcon';
import MapIcon from '../icons/MapIcon';
import {
  getInstallersEnergytypeSummary,
  getInstallersInternalSummary,
} from '../services/InstallerService';
import { getEmobilitySummaryForDiagram } from '../services/EmobilityService';
import { getPhotovoltaicsSummaryForDiagram } from '../services/FeedInSystemsService';

export default {
  name: 'DashboardPage',
  components: {
    DashboardComponent,
    NewGlassCard,
    MapPointerIcon,
    MapIcon,
    SearchFieldWithSuggestions,
  },
  created() {
    this.getOrdersSummaryFromBackend();
    this.getInstallersEnergyTypeSummaryFromBackend();
    this.getEmobilitySummaryForDiagram();
    this.getInstallersInternalSummary();
    this.getPVOrdersSummaryFromBackend();
    this.getOrderByCaseNumber();
  },

  data() {
    return {
      dataReady: false,
      dataReadyInstallerEnergyType: false,
      dataReadyEMobility: false,
      dataReadyInstallers: false,
      dataReadyFeedInSystems: false,
      ordersSummary: {},
      feedInSystemsSummary: {},
      installersInternalSummary: {},
      emobilitySummary: {},
      installersEnergyTypeSummary: {},
      orderNumbersList: [],
      areOrderNumbersReady: false,
    };
  },
  computed: {
    dataFeedInSystemsObject() {
      return this.feedInSystemsSummary;
    },
    dataOrderObject() {
      return this.ordersSummary;
    },
    dataInstallerEnergyTypeObject() {
      return this.installersEnergyTypeSummary;
    },
    dataEMobilityObject() {
      return this.emobilitySummary;
    },
    dataInstallerObject() {
      return this.installersInternalSummary;
    },
    searchfieldLabels() {
      return {
        label: this.$t('dashboard.search_input_label'),
        noDataText: this.$t('dashboard.search_error_message'),
        searchFieldPrefix: this.$t('dashboard.search_field_prefix'),
      };
    },
  },
  methods: {
    redirectToSelectedPVJourneyNewOrders($event) {
      let statusFilter;
      switch ($event) {
        case 'photovoltaik':
          statusFilter = 'ORDER_CREATED';
          break;
        case 'pv-inbetriebnahme':
          statusFilter = 'ORDER_IN_IMPLEMENTATION';
          break;
        case 'pv-inbetriebnahme-protokoll':
          statusFilter = 'ORDER_FULFILLED';
          break;
      }

      this.$router.push({
        name: 'FeedInSystemsPage',
        query: {
          page: 1,
          journey: 'all',
          status: statusFilter,
          performance: 'all',
        },
      });
    },
    getOrdersSummaryFromBackend() {
      orderService
        .getOrdersSummaryForDiagram()
        .then((res) => {
          if (res.getOrdersSummaryForDiagram) {
            this.ordersSummary = res.getOrdersSummaryForDiagram;
            this.dataReady = true;
          }
        })
        .catch((err) => {
          this.dataReady = true;
          console.log(err);
        });
    },
    getPVOrdersSummaryFromBackend() {
      getPhotovoltaicsSummaryForDiagram()
        .then((res) => {
          if (res.getPhotovoltaicsSummaryForDiagram) {
            this.feedInSystemsSummary = res.getPhotovoltaicsSummaryForDiagram;
            this.dataReadyFeedInSystems = true;
          }
        })
        .catch((err) => {
          this.dataReadyFeedInSystems = true;
          console.log(err);
        });
    },
    getInstallersEnergyTypeSummaryFromBackend() {
      getInstallersEnergytypeSummary()
        .then((res) => {
          if (res.getInstallersEnergytypeSummary) {
            this.installersEnergyTypeSummary =
              res.getInstallersEnergytypeSummary;
            this.dataReadyInstallersEnergyType = true;
          }
        })
        .catch((err) => {
          this.dataReadyInstallersEnergyType = true;
          console.log(err);
        });
    },
    getInstallersInternalSummary() {
      getInstallersInternalSummary()
        .then((res) => {
          if (res.getInstallersInternalSummary) {
            this.installersInternalSummary = res.getInstallersInternalSummary;
            this.dataReadyInstallers = true;
          }
        })
        .catch((err) => {
          this.dataReadyInstallers = true;
          console.log(err);
        });
    },
    getEmobilitySummaryForDiagram() {
      getEmobilitySummaryForDiagram()
        .then((res) => {
          if (
            res.getEmobilitySummaryForDiagram.count_orders ||
            res.getEmobilitySummaryForDiagram.count_orders === 0
          ) {
            this.emobilitySummary.count_loading_infrastructure =
              res.getEmobilitySummaryForDiagram.count_orders;
            this.dataReadyEMobility = true;
          }
        })
        .catch((err) => {
          this.dataReadyEMobility = true;
          console.log(err);
        });
    },
    getOrderByCaseNumber(searchValue) {
      orderService
        .searchByCaseNumber(searchValue)
        .then((res) => {
          this.orderNumbersList = res.caseNumberSearch;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchFieldValueHandler(value) {
      let orderjourney;
      switch (value?.obj_type) {
        case 'order':
          orderjourney = 'netzanschluss';
          break;
        case 'emobility':
          orderjourney = 'emobilitaet';
          break;
        case 'photovoltaic':
          orderjourney = 'einspeiseanlagen';
          break;
      }
      this.$router.push(`/${orderjourney}/${value.id}`);
    },
    searchInputChangeHandler(value) {
      if (value && value.length >= 3) {
        this.getOrderByCaseNumber(value);
        this.areOrderNumbersReady = true;
      } else {
        this.getOrderByCaseNumber('');
        this.areOrderNumbersReady = true;
      }
    },
    formatCaseNumber(item) {
      return `${item.case_number} (${item.summary})`;
    },
  },
};
</script>

<style scoped>
.service-container {
  display: flex;
  background: inherit;
  justify-content: space-between;
}
.icon-container {
  display: flex;
  flex-direction: column;
  float: right;
  margin-right: 40px;
}
.frosted_glas {
  width: 49%;
}
.map_pointer_icon {
  margin-left: 75px;
}

.title_small {
  font-size: 16px !important;
}
.frosted_glas_card_title {
  width: 20%;
}

.dashboard-and-order-number-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-icon-and-text {
  display: flex;
  font-size: 60px;
  align-items: center;
}

.dashboard-icon-and-text p {
  margin: 0;
}

.search-input {
  border-radius: 8px;
}

.frosted_glass_card {
  background: #6e6e6eb3 0% 0% no-repeat padding-box;
  background: #c6c6c680 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 24px;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

@media screen and (max-width: 1279px) and (min-width: 1024px) {
  .service-container {
    display: flex;
    background: inherit;
    justify-content: space-between;
    flex-direction: column;
  }

  .frosted_glas {
    width: 100%;
  }
}

.grid-style {
  display: grid;
}
</style>
