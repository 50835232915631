<template>
  <div>
    <div v-if="!isLoading" style="display: flex; justify-content: flex-end">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            large
            color="primary"
            @click="fetchCsvData"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark> mdi-download </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('general.btn.download_hover') }}</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            large
            color="primary"
            @click="fetchCsvData"
            v-bind="attrs"
            v-on="on"
          >
            <v-progress-circular
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </template>
        <span>{{ $t('general.btn.download_hover') }}</span>
      </v-tooltip>
    </div>

    <div v-if="isError" class="mt-4">
      <NewAlert color="ERROR" :message="$t('error_message.file_download')" />
    </div>
  </div>
</template>

<script>
import { NewAlert } from '@newnetzportal/netzportal-webapp-storybook-components';
import { writeFile } from 'xlsx';
export default {
  name: 'InstallerDownloadData',
  components: {
    NewAlert,
  },
  data() {
    return {
      getInstallerDataUrl: process.env.VUE_APP_INSTALLER_DATA_EXPORT,
      isLoading: false,
      isError: false,
    };
  },
  methods: {
    async fetchCsvData() {
      this.isLoading = true;
      let data;
      try {
        const requestOptions = {
          method: 'POST',
          headers: { Accept: '*/*', 'Content-Type': 'application/json' },
          body: JSON.stringify(this.getQueryBody()),
        };
        const response = await fetch(this.getInstallerDataUrl, requestOptions);
        if (response.status !== 200) {
          throw new Error('Data could not be downloaded');
        }
        data = await response.json();
      } catch (error) {
        this.isLoading = false;
        this.isError = true;

        setTimeout(() => {
          this.isError = false;
        }, 5000);
      }
      this.isLoading = false;

      writeFile(
        data?.message,
        `Installer_Data_${new Date().toISOString()}.xlsx`
      );
    },
    getQueryBody() {
      return {
        concession_division_en: this.$route.query.energy_type
          ? this.$route.query.energy_type
          : 'all',
        status: this.$route.query.status ? this.$route.query.status : 'all',
        expiry: this.$route.query.expiry ? this.$route.query.expiry : 'all',
      };
    },
  },
};
</script>
