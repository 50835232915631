<template>
  <NewGlassCard class="card-without-title" title="">
    <div class="header_container_new">
      <v-btn class="back-button" @click="saveChangesAndGoOnePageBack()"
        ><v-icon color="#6E6E6E">mdi-arrow-left</v-icon></v-btn
      >
      <div class="connection_container">
        <div class="connection_client">Kunde: {{ selectedOrderApplicant }}</div>
        <div class="connection_location">Bauort: {{ selectedOrderAdress }}</div>
      </div>
      <v-btn class="contact_button" @click="startCall()">
        <v-icon color="white" x-large>mdi-phone</v-icon>

        <!-- <phone-icon width="35px" height="35px" iconColor="#ffffff" /> -->
      </v-btn>
      <v-btn class="contact_button" @click="sendMail()">
        <v-icon color="white" x-large>mdi-email</v-icon>

        <!-- <email-icon width="40px" height="40px" iconColor="#ffffff" /> -->
      </v-btn>
      <v-btn
        rounded
        style="color: white; box-shadow: none; pointer-events: none"
        class="ml-2 mr-2"
        :color="orderStatusCofig[1]"
      >
        {{ orderStatusCofig[0] }}
      </v-btn>
      <div style="width: 40%; justify-content: flex-end; display: flex">
        <v-btn
          v-show="selectedOrderStatus === 'ORDER_CREATED'"
          class="mr-4"
          @click="updateOrderStatusFunc('ORDER_TECHNICAL_FEASIBILITY')"
          color="primary"
        >
          Technische Prüfung starten
        </v-btn>
        <v-btn
          v-show="selectedOrderStatus === 'ORDER_CONFIRMED'"
          class="mr-4"
          @click="updateOrderStatusFunc('ORDER_TECHNICAL_FEASIBILITY')"
          color="primary"
        >
          {{ $t('header_details.check_again') }}
        </v-btn>
        <!--<v-btn v-show="selectedOrderStatus === 'ORDER_CONFIRMED'"
              class="mr-4"
              @click="updateOrderStatusFunc('ORDER_APPOINTMENT_PENDING')"
              color="primary">
          Terminabstimmung starten
        </v-btn>-->
      </div>
    </div>
  </NewGlassCard>
</template>

<script>
import { NewGlassCard } from '@newnetzportal/netzportal-webapp-storybook-components';
import userService from '../../services/UserService';
import { translateOrderStatus } from './OrderStatusConfig';

export default {
  name: 'HeaderDetails',
  components: {
    NewGlassCard,
  },
  computed: {
    userPersonalData() {
      return this.$store.getters.getUserId;
    },
    selectedOrderStatus() {
      return this.$store.getters.getSelectedOrder.status;
    },
    selectedOrder() {
      return this.$store.getters.getSelectedOrder;
    },
    selectedOrderApplicant() {
      return this.$store.getters.getSelectedOrderApplicantName;
    },
    selectedOrderAdress() {
      return this.$store.getters.getSelectedOrderAdress;
    },
    selectedProductsForEmail() {
      return this.$store.getters.getSelectedOrder.products
        ? this.$store.getters.getSelectedOrder.products
            .replace('&', 'und')
            .replace(',', '')
            .split(' ')
            .join('%20')
        : '';
    },
    selectedAdressforEmail() {
      return this.$store.getters.getSelectedOrder
        .product_installation_full_address
        ? this.$store.getters.getSelectedOrder.product_installation_full_address
            .street +
            '%20' +
            this.$store.getters.getSelectedOrder
              .product_installation_full_address.street_number +
            '%20' +
            this.$store.getters.getSelectedOrder
              .product_installation_full_address.zip +
            '%20' +
            this.$store.getters.getSelectedOrder
              .product_installation_full_address.city
        : this.$store.getters.getSelectedOrder
            .product_installation_with_plot_number_address
            .new_building_area_name +
            '%20' +
            'Gemarkung:' +
            '%20' +
            this.$store.getters.getSelectedOrder
              .product_installation_with_plot_number_address.district +
            '%20' +
            'Flur:' +
            '%20' +
            this.$store.getters.getSelectedOrder
              .product_installation_with_plot_number_address.landing +
            '%20' +
            'Flurstück:' +
            '%20' +
            this.$store.getters.getSelectedOrder
              .product_installation_with_plot_number_address.plot +
            '%20' +
            this.$store.getters.getSelectedOrder
              .product_installation_with_plot_number_address.zip +
            '%20' +
            this.$store.getters.getSelectedOrder
              .product_installation_with_plot_number_address.location;
    },
    orderStatusCofig() {
      return translateOrderStatus(this.$store.getters.getSelectedOrder.status);
    },
  },

  methods: {
    updateOrderStatusFunc(status) {
      const currentOrder = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedOrder)
      );
      userService
        .updateOrderStatus(
          {
            id: currentOrder.id,
            request_date: currentOrder.request_date,
            old_value: {
              status: currentOrder.status,
            },
            new_value: {
              status: status,
            },
          },
          this.userPersonalData
        )
        .then((res) => {
          if (res) {
            const new_status = { status: status };
            this.$store.commit('updateSelectedOrderData', new_status);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveChangesAndGoOnePageBack() {
      const previousPage = this.$store.getters.getPreviousPage;
      if (previousPage) {
        this.$router.push(previousPage);
      } else {
        this.$router.push('/');
      }
    },
    sendMail() {
      window.open(
        `mailto:${this.selectedOrder.applicant.email}?subject=${this.selectedProductsForEmail}%20${this.selectedAdressforEmail}`
      );
    },
    startCall() {
      window.open(`tel:${this.selectedOrder.applicant.phone_number}`);
    },
    getAppointmentChangedData() {
      const currentOrder = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedOrder)
      );
      const construction_data = {
        internal_construction_management:
          currentOrder.internal_construction_management,
        groundwork_company: currentOrder.groundwork_company,
        assembly_electricity_company: currentOrder.assembly_electricity_company,
        assembly_gas_water_company: currentOrder.assembly_gas_water_company,
      };
      return construction_data;
    },
  },
};
</script>

<style>
.card-without-title > .frosted_glass_card_title {
  margin-bottom: 0px !important;
}
.header_container_new {
  display: flex;
  align-items: center;
  justify-content: center;
}
.connection_container {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.connection_client {
  font-size: 25px;
}
.connection_location {
  font-size: 20px;
}
.back-button {
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  height: 35px !important;
  width: 90px;
  box-shadow: none;
  margin-right: 30px;
}
.contact_button {
  background-color: transparent !important;
  box-shadow: none !important;
  width: 20px;
  margin: 5px;
}
</style>
