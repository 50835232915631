<template>
  <div>
    <NewGlassCard
      class="default-mb"
      :title="$t('details_page.appointment_pending')"
    >
      <div class="construction_calendar">
        <calendar-component></calendar-component>
        <div class="side_selection">
          <form @submit.prevent="submit">
            <internal-construction-management
              :managers="constructionManagers"
              v-model="form.internal_construction_management"
              :v="$v.form.internal_construction_management"
            >
            </internal-construction-management>

            <groundwork-component
              :companies="groundworkCompanies"
              v-model="form.groundwork_company"
              :v="$v.form.groundwork_company"
            >
            </groundwork-component>

            <assembly-electricity-component
              v-if="
                this.selectedOrder.product.includes('electricity') ||
                this.selectedOrder.product.includes('eMobility')
              "
              :companies="assemblyElectricityCompanies"
              v-model="form.assembly_electricity_company"
              :v="$v.form.assembly_electricity_company"
            >
            </assembly-electricity-component>

            <assembly-gas-water-component
              v-if="
                this.selectedOrder.product.includes('gas') ||
                this.selectedOrder.product.includes('water')
              "
              :companies="assemblyGasAndWaterCompanies"
              v-model="form.assembly_gas_water_company"
              :v="$v.form.assembly_gas_water_company"
            >
            </assembly-gas-water-component>
          </form>
        </div>
      </div>
      <footer-scheduling
        :showLoading="showLoading"
        :alertError="alertError"
        :value="noteValue"
        @save-data="submit"
        :showErrors="showErrors"
        @update-note-data="updateNoteChangedObject"
        @save-current-data="saveCurrentData"
      />
    </NewGlassCard>
  </div>
</template>

<script>
import { NewGlassCard } from '@newnetzportal/netzportal-webapp-storybook-components';
import CalendarComponent from '../calendar-component/CalendarComponent';
import { getConstructionCompanies } from '../../services/ConstructionCompaniesService';
import FooterScheduling from './FooterScheduling';
import InternalConstructionManagement from './InternalConstructionManagement';
import GroundworkComponent from './GroundworkComponent';
import AssemblyElectricityComponent from './AssemblyElectricityComponent';
import AssemblyGasWaterComponent from './AssemblyGasWaterComponent';
import { helpers } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import userService from '../../services/UserService';

export default {
  name: 'ConstructionComponent',
  props: {
    showConstructionComponent: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    InternalConstructionManagement,
    NewGlassCard,
    CalendarComponent,
    FooterScheduling,
    GroundworkComponent,
    AssemblyElectricityComponent,
    AssemblyGasWaterComponent,
  },
  mixins: [validationMixin],
  data() {
    return {
      construction_planned_date_selected: false,
      constructionManagers: [],
      groundworkCompanies: [],
      showLoading: false,
      assemblyElectricityCompanies: [],
      assemblyGasAndWaterCompanies: [],
      showErrors: false,
      changedObject: this.$store.getters.getChangedObject,
      selectedOrder: this.$store.getters.getSelectedOrder,
      noteValue: { note: '' },
      alertError: false,
      showComponent: this.showConstructionComponent,
      emptyConstructionPlanedDate: {
        start_time: { start_hour: '08', start_minute: '00' },
        end_time: { end_hour: '18', end_minute: '00' },
        dates: [new Date().toISOString().slice(0, 10)],
      },
    };
  },
  created() {
    this.changedObject = this.getChangedData();
    this.$store.commit('setChangedObject', this.changedObject);
    this.$store.commit(
      'setOldValueObject',
      JSON.parse(JSON.stringify(this.changedObject))
    );
    getConstructionCompanies()
      .then((res) => {
        const responseData = res.constructionCompanies;
        this.constructionManagers = responseData.construction_managers;
        this.filterDataByTypeOfCompany(responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  watch: {
    allErrors: function (err) {
      !err ? (this.showErrors = false) : '';
    },
  },
  computed: {
    form: {
      get() {
        return {
          internal_construction_management: {
            construction_manager: this.$store.getters.getChangedObject
              .internal_construction_management
              ? this.$store.getters.getChangedObject
                  .internal_construction_management.construction_manager
              : '',
            construction_planned_date: this.$store.getters.getChangedObject
              .internal_construction_management
              ? this.$store.getters.getChangedObject
                  .internal_construction_management.construction_planned_date
              : this.emptyConstructionPlanedDate,
            regional_team: this.$store.getters.getChangedObject
              .internal_construction_management
              ? this.$store.getters.getChangedObject
                  .internal_construction_management.regional_team
              : { team_id: '', team_name: '' },
          },
          groundwork_company: {
            company_name: this.$store.getters.getChangedObject
              .groundwork_company
              ? this.$store.getters.getChangedObject.groundwork_company
                  .company_name
              : '',
            contact_person: this.$store.getters.getChangedObject
              .groundwork_company
              ? this.$store.getters.getChangedObject.groundwork_company
                  .contact_person
              : '',
            groundwork_by_client: this.$store.getters.getChangedObject
              .groundwork_company
              ? this.$store.getters.getChangedObject.groundwork_company
                  .groundwork_by_client
              : '',
            construction_planned_date: this.$store.getters.getChangedObject
              .groundwork_company
              ? this.$store.getters.getChangedObject.groundwork_company
                  .construction_planned_date
              : this.emptyConstructionPlanedDate,
          },
          assembly_electricity_company: {
            company_name: this.$store.getters.getChangedObject
              .assembly_electricity_company
              ? this.$store.getters.getChangedObject
                  .assembly_electricity_company.company_name
              : '',
            contact_person: this.$store.getters.getChangedObject
              .assembly_electricity_company
              ? this.$store.getters.getChangedObject
                  .assembly_electricity_company.contact_person
              : '',
            construction_planned_date: this.$store.getters.getChangedObject
              .assembly_electricity_company
              ? this.$store.getters.getChangedObject
                  .assembly_electricity_company.construction_planned_date
              : this.emptyConstructionPlanedDate,
          },
          assembly_gas_water_company: {
            company_name: this.$store.getters.getChangedObject
              .assembly_gas_water_company
              ? this.$store.getters.getChangedObject.assembly_gas_water_company
                  .company_name
              : '',
            contact_person: this.$store.getters.getChangedObject
              .assembly_gas_water_company
              ? this.$store.getters.getChangedObject.assembly_gas_water_company
                  .contact_person
              : '',
            construction_planned_date: this.$store.getters.getChangedObject
              .assembly_gas_water_company
              ? this.$store.getters.getChangedObject.assembly_gas_water_company
                  .construction_planned_date
              : this.emptyConstructionPlanedDate,
          },
        };
      },
    },
    userPersonalData() {
      return this.$store.getters.getUserId;
    },
    allErrors() {
      return this.$v.form.$invalid;
    },
  },
  validations: {
    form: {
      internal_construction_management: {
        construction_manager: {
          required(value) {
            return helpers.req(value);
          },
        },
        construction_planned_date: {
          required(value) {
            return helpers.req(value);
          },
        },
        regional_team: {
          required(value) {
            return helpers.req(value);
          },
        },
      },
      groundwork_company: {
        company_name: {
          required(value) {
            return helpers.req(value);
          },
        },
        contact_person: {
          required(value) {
            return helpers.req(value);
          },
        },
        groundwork_by_client: {
          required(value) {
            return helpers.req(value);
          },
        },
        construction_planned_date: {
          required(value) {
            return helpers.req(value);
          },
        },
      },
      assembly_electricity_company: {
        company_name: {
          required(value) {
            return (
              helpers.req(value) ||
              (!this.selectedOrder.product.includes('electricity') &&
                !this.selectedOrder.product.includes('eMobility'))
            );
          },
        },
        contact_person: {
          required(value) {
            return (
              helpers.req(value) ||
              (!this.selectedOrder.product.includes('electricity') &&
                !this.selectedOrder.product.includes('eMobility'))
            );
          },
        },
        construction_planned_date: {
          required(value) {
            return (
              helpers.req(value) ||
              (!this.selectedOrder.product.includes('electricity') &&
                !this.selectedOrder.product.includes('eMobility'))
            );
          },
        },
      },
      assembly_gas_water_company: {
        company_name: {
          required(value) {
            return (
              helpers.req(value) ||
              (!this.selectedOrder.product.includes('gas') &&
                !this.selectedOrder.product.includes('water'))
            );
          },
        },
        contact_person: {
          required(value) {
            return (
              helpers.req(value) ||
              (!this.selectedOrder.product.includes('gas') &&
                !this.selectedOrder.product.includes('water'))
            );
          },
        },
        construction_planned_date: {
          required(value) {
            return (
              helpers.req(value) ||
              (!this.selectedOrder.product.includes('gas') &&
                !this.selectedOrder.product.includes('water'))
            );
          },
        },
      },
    },
  },
  methods: {
    updateNoteChangedObject(value) {
      this.$store.commit('updateNoteChangedObject', value);
    },
    saveCurrentData() {
      this.sendConstractionDataToBackend('pending');
    },
    submit() {
      this.$v.form.$touch();
      if (this.allErrors === true) {
        this.showErrors = true;
      } else {
        this.sendConstractionDataToBackend('completed');
        this.updateStatus();
        this.showComponent = false;
        this.$emit('show-component', this.showComponent);
      }
    },
    sendConstractionDataToBackend(status) {
      const currentOrder = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedOrder)
      );
      const requestBody = {
        id: this.selectedOrder.id,
        user_id: this.selectedOrder.user_id,
        request_date: this.selectedOrder.request_date,
        new_value: this.$store.getters.getChangedObject,
        old_value: this.$store.getters.getOldValueObject,
        note: this.$store.getters.getNoteChangedObject,
        status: status,
        title: currentOrder.product_installation_full_address
          ? currentOrder.product_installation_full_address.street +
            ' ' +
            currentOrder.product_installation_full_address.street_number +
            ', ' +
            currentOrder.product_installation_full_address.zip +
            ' ' +
            currentOrder.product_installation_full_address.city
          : 'Bauort: ' +
            currentOrder.product_installation_with_plot_number_address
              .new_building_area_name +
            ', ' +
            'Gemarkung: ' +
            currentOrder.product_installation_with_plot_number_address
              .district +
            ', Flur:' +
            currentOrder.product_installation_with_plot_number_address.landing +
            ', Flurstück: ' +
            currentOrder.product_installation_with_plot_number_address.plot +
            ', ' +
            currentOrder.product_installation_with_plot_number_address.zip +
            ' ' +
            currentOrder.product_installation_with_plot_number_address.location,
      };
      if (status === 'completed') {
        this.showLoading = true;
      }
      userService
        .updateConstructionData(requestBody, this.userPersonalData)
        .then((res) => {
          console.log(res);
          this.showLoading = false;
          this.$store.commit('updateSelectedOrderData', this.changedObject);
        })
        .catch((err) => {
          this.alertError = true;
          console.log(err);
        });
    },
    getChangedData() {
      const currentOrder = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedOrder)
      );
      const construction_data = {
        internal_construction_management:
          currentOrder.internal_construction_management,
        groundwork_company: currentOrder.groundwork_company,
        assembly_electricity_company: currentOrder.assembly_electricity_company,
        assembly_gas_water_company: currentOrder.assembly_gas_water_company,
      };
      return construction_data;
    },
    filterDataByTypeOfCompany(companiesArray) {
      companiesArray.construction_companies.forEach((company) => {
        const isContactPersonForGroundwork = company.contact_persons.some(
          function (contactPerson) {
            return contactPerson.groundwork === true;
          }
        );
        if (isContactPersonForGroundwork) {
          this.groundworkCompanies.push(company);
        }
        const isContactPersonForAssemblyElectricity =
          company.contact_persons.some(function (contactPerson) {
            return contactPerson.assembly_electricity === true;
          });
        if (isContactPersonForAssemblyElectricity) {
          this.assemblyElectricityCompanies.push(company);
        }
        const isContactPersonForAssemblyGasAndWater =
          company.contact_persons.some(function (contactPerson) {
            return contactPerson.assembly_gas_water === true;
          });
        if (isContactPersonForAssemblyGasAndWater) {
          this.assemblyGasAndWaterCompanies.push(company);
        }
      });
    },
    updateStatus() {
      const new_status = { status: 'ORDER_APPOINTMENT_CONFIRMED' };
      this.$store.commit('updateSelectedOrderData', new_status);
    },
  },
};
</script>

<style>
.construction_calendar {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 25px;
}
.side_selection {
  display: flex;
  flex-direction: column;
}
.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: white;
}
.v-input.theme--light.v-input--selection-controls.v-input--checkbox {
  margin: 0px !important;
  padding: 0px !important;
}

@media screen and (max-width: 1024px) {
  .construction_calendar {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 25px;
  }
}
</style>
